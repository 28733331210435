import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { useAAuth } from "core";
import { match } from "path-to-regexp";
import { webBaseUrl } from "@kgsport-cms/config";
const routes: Array<RouteRecordRaw> = [
  {
    path: "/entry/login",
    name: "agentLogin",
    component: () =>
      import(/* webpackChunkName: "entry" */ "scaffolds/cms-web-agent/views/download"),
    meta: {
      emptyPage: true,
    },
  },
  {
    path: "/",
    name: "agentHome",
    component: () => import(/* webpackChunkName: "home" */ "scaffolds/cms-web-agent/views/home"),
  },
  {
    path: "/gameRecord",
    name: "gameRecord",
    component: () =>
      import(/* webpackChunkName: "gameRecord" */ "scaffolds/cms-web-agent/views/gameRecord"),
  },
  {
    path: "/member",
    name: "member",
    component: () =>
      import(/* webpackChunkName: "menber" */ "scaffolds/cms-web-agent/views/menber"),
  },
  {
    path: "/rankingList",
    name: "rankingList",
    component: () =>
      import(/* webpackChunkName: "rankingList" */ "scaffolds/cms-web-agent/views/rankingList/"),
  },
  {
    path: "/subordinateFinance",
    name: "subordinateFinance",
    component: () =>
      import(
        /* webpackChunkName: "subordinateFinance" */ "scaffolds/cms-web-agent/views/subordinateFinance/"
      ),
  },
  // {
  //   path: "/financialStatement",
  //   name: "financialStatement",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "financialStatement" */ "scaffolds/cms-web-agent/views/financialStatement"
  //     ),
  // },
  {
    path: "/commissionReport",
    name: "commissionReport",
    component: () =>
      import(
        /* webpackChunkName: "commissionReport" */ "scaffolds/cms-web-agent/views/commissionReport"
      ),
  },
  {
    path: "/agentReport",
    name: "agentReport",
    component: () =>
      import(
        /* webpackChunkName: "commissionReport" */ "scaffolds/cms-web-agent/views/agentReport"
      ),
  },
  // {
  //   path: "/spreadLink",
  //   name: "spreadLink",
  //   component: () =>
  //     import(/* webpackChunkName: "spreadLink" */ "scaffolds/cms-web-agent/views/spreadLink"),
  // },
  {
    path: "/invitationCodeManage",
    name: "invitationCodeManage",
    component: () =>
      import(
        /* webpackChunkName: "invitationCodeManage" */ "scaffolds/cms-web-agent/views/invitationCodeManage/"
      ),
  },
  // {
  //   path: "/accountChange",
  //   name: "accountChange",
  //   component: () =>
  //     import(/* webpackChunkName: "accountChange" */ "scaffolds/cms-web-agent/views/accountChange"),
  // },
  // {
  //   path: "/agentContact",
  //   name: "agentContact",
  //   component: () =>
  //     import(/* webpackChunkName: "agentContent" */ "scaffolds/cms-web-agent/views/agentContact/"),
  // },
  {
    path: "/agentUserCenter",
    name: "agentUserCenter",
    component: () =>
      import(
        /* webpackChunkName: "agentUserCenter" */ "scaffolds/cms-web-agent/views/agentUserCenter/"
      ),
  },
  {
    path: "/overflowApply",
    name: "overflowApply",
    component: () =>
      import(
        /* webpackChunkName: "agentOverflowApply" */ "scaffolds/cms-web-agent/views/overflowApply/"
      ),
  },
  {
    path: "/rechargeOrders",
    name: "RechargeOrders",
    component: () =>
      import(
        /* webpackChunkName: "agentRechargeOrders" */ "scaffolds/cms-web-agent/views/rechargeOrders/"
      ),
  },
  {
    path: "/withdrawOrders",
    name: "WithdrawOrders",
    component: () =>
      import(
        /* webpackChunkName: "agentWithdrawOrders" */ "scaffolds/cms-web-agent/views/withdrawOrders/"
      ),
  },
];
const router = createRouter({
  history: createWebHistory(process.env.NODE_ENV === "production" ? webBaseUrl : "/agent"),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 };
  },
});

const matchPath = (path: string) => {
  return (
    ["/entry/:path*", "/help", "/download"].filter(item => {
      const end = match(item, { decode: decodeURIComponent });
      return !(end(path) === false);
    }).length > 0
  );
};
router.beforeEach((to, form, next) => {
  const { isLogin } = useAAuth();
  if (matchPath(to.path)) {
    next();
  } else if (isLogin.value) {
    next();
  } else {
    next("/entry/login");
  }
});
export default router;

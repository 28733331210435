/**
 * 1、全局弹窗
 */
import { createVNode, getCurrentInstance, render, inject } from "vue";
import {
  MODAL_INJECTION_KEY,
  ModalInjectionContext,
  modal,
  ModalWrap,
  ModalContext,
} from "commom/modal";
interface ModalOption {
  title?: any;
  footer?: any;
  content?: any;
  contentText?: any;
  onClose?: () => void;
  parentContext?: any;
  appContext?: any;
  bodyClass?: string | string[] | null;
  transitionName?: string;
  position?: string;
  destroyOnClose?: boolean;
  [key: string]: any;
}
function createEl() {
  const div = document.createElement("div");
  div.classList.add("cms-modal-container");
  document.body.appendChild(div);
  return div;
}
let _div: any = null;
let _vm: any = null;
export function useModalContext() {
  return inject<ModalInjectionContext>(MODAL_INJECTION_KEY, ModalContext) || null;
}
export const destroyAll = () => {
  if (_vm) {
    render(null, _div);
    _vm?.component.update();
    _vm = null;
    document.body.removeChild(_div);
  }
};
export function useModal(option: ModalOption) {
  const close = () => {
    update({
      visible: false,
    });
  };
  const update = (newConfig: any) => {
    if (!_vm) return;
    baseConfig = {
      ...baseConfig,
      ...newConfig,
    };
    Object.assign(_vm.component.props, baseConfig);
    _vm.component.update();
  };
  const { title, footer = null, bodyClass = null, onClose = close, ...other } = option || {};
  const appContext = getCurrentInstance() && (getCurrentInstance() as any).appContext;
  let baseConfig = {
    visible: true,
    footer,
    title,
    ...other,
    onClose,
    bodyClass,
  };
  const renderModal = (props: any, el: HTMLElement) => {
    const vm = createVNode(ModalWrap, { ...props, close, update, baseConfig });
    vm.appContext = option.parentContext || option.appContext || appContext || vm.appContext;
    render(vm, el);
    return vm;
  };
  const open = (modalProps = {}, option: Partial<ModalOption> = {}) => {
    if (_vm) {
      update({
        visible: true,
        modalProps,
        ...option,
      });
      return;
    }
    _div = createEl();
    _vm = renderModal(Object.assign(baseConfig, { modalProps }, option), _div);
  };
  return {
    open,
    close,
    update,
    destroyAll,
    modal,
  };
}

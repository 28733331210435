import { InternalRuleItem, Value, RuleItem } from "async-validator";
import {
  passowrd,
  username,
  email,
  phone,
  verificationCode,
  qq,
  wechat,
  cardHolder,
  cardNo,
  withdrawPass,
  hiddenPhone,
  nickName,
} from "./reg.validator";

/*
 * 函数功能 预留返回国际化的功能 和自定义
 */

// 校验用户名
export function usernameRule() {
  const rule = [
    { required: true, message: "请输入用户名", trigger: "change" },
    {
      pattern: username,
      message: "用户名由4-20位字符组成",
      trigger: "change",
    },
  ];
  return rule;
}

// 校验密码
export function passwordRule(): RuleItem[] {
  const rule = [
    { required: true, message: "请输入密码", trigger: "change" },
    {
      pattern: passowrd,
      message: "4-20位字符组成",
      trigger: "change",
    },
  ];
  return rule;
}

// 校验邮箱
export function emailRule() {
  const rule = [
    { required: true, message: "请输入邮箱", trigger: "change" },
    {
      pattern: email,
      message: "邮箱格式不正确",
      trigger: "change",
    },
  ];
  return rule;
}

// 校验验证码
export function verificationCodeRule() {
  const rule = [
    { required: true, message: "请输入验证码", trigger: "change" },
    {
      pattern: verificationCode,
      message: "请输入正确的6位验证码",
      trigger: "change",
    },
  ];
  return rule;
}
// 校验提现密码
export function withdrawPassRule() {
  const rule = [
    { required: true, message: "请输入提现密码", trigger: "change" },
    {
      pattern: withdrawPass,
      message: "请输入提现密码（6位数字）",
      trigger: "change",
    },
  ];
  return rule;
}
// 校验手机号号
export function phoneNumberRule() {
  const rule = [
    { required: true, message: "请输入手机号", trigger: "change" },
    {
      pattern: phone,
      message: "请输入正确的手机号4-20位",
      trigger: "change",
    },
  ];
  return rule;
}
//  校验缺省手机号
export function phoneHiddenNumberRule() {
  const rule = [
    { required: true, message: "请输入手机号", trigger: "change" },
    {
      pattern: hiddenPhone,
      message: "手机号不正确",
      trigger: "change",
    },
  ];
  return rule;
}

// 校验微信号
export function wechatRule() {
  const rule = [
    { required: true, message: "请输入微信号", trigger: "change" },
    {
      pattern: wechat,
      message: "请输入正确的微信号",
      trigger: "change",
    },
  ];
  return rule;
}

// 校验qq号
export function qqRule() {
  const rule = [
    { required: true, message: "请输入QQ号", trigger: "change" },
    {
      pattern: qq,
      message: "请输入正确的QQ号码",
      trigger: "change",
    },
  ];
  return rule;
}

// 校验用户名和密码是否相同
export function usernamePassword(props: any): any[] {
  const validator = async (rule: InternalRuleItem, value: Value) => {
    // tips: 禅道 https://zentao.wzapp000.com/zentao/bug-view-19890.html 去掉
    // if (value === props.username) {
    //   return Promise.reject("用户名和密码不能相同");
    // }
    return Promise.resolve();
  };
  const rule = [
    {
      validator,
    },
  ];
  return rule;
}
// 校验两次密码是否相同
export function confirmPasswordRule(props: any, asFiled?: string) {
  const validator = (rule: InternalRuleItem, value: Value) => {
    const filed = asFiled ? props[asFiled] : props.password;

    if (value !== filed) {
      return Promise.reject("两次密码输入不一致");
    }
    return Promise.resolve();
  };
  const rule = [
    {
      validator,
    },
  ];
  return rule;
}
// 新旧密码校验
export function confirmNewAndOldPasswordRule(props: any, asFiled?: string) {
  const validator = (rule: InternalRuleItem, value: Value) => {
    const filed = asFiled ? props[asFiled] : props.password;

    if (value === filed) {
      return Promise.reject("新密码和旧密码不能相同！");
    }
    return Promise.resolve();
  };
  const rule = [
    {
      validator,
    },
  ];
  return rule;
}

// 校验绑定银行卡
export function cardHolderRule() {
  const rule = [
    { required: true, message: "请输入的持卡人姓名", trigger: "change" },
    {
      pattern: cardHolder,
      message: "持卡人姓名有误",
      trigger: "change",
    },
  ];
  return rule;
}
// 银行卡号
export function cardNoRule() {
  const rule = [
    { required: true, message: "请输入银行卡号", trigger: "change" },
    {
      pattern: cardNo,
      message: "银行卡号长度为16-19位",
      trigger: "change",
    },
  ];
  return rule;
}
// 选择银行卡
export function bankNameRule() {
  const rule = [{ required: true, message: "请选择银行卡", trigger: "change" }];
  return rule;
}
// 开户行地址
export function addressRule() {
  const rule = [{ required: true, message: "请输入开户行地址", trigger: "change" }];
  return rule;
}

// 检验绑定虚拟账户账户
export function aliasRule() {
  const rule = [{ required: true, message: "请输入虚拟币账户别名", trigger: "change" }];
  return rule;
}

// 虚拟币账户
export function usdtCardNoRule() {
  const rule = [{ required: true, message: "请输入虚拟币账户", trigger: "change" }];
  return rule;
}

// 昵称 要求加验证规则 kiki
export function nicknameRule() {
  const rule = [
    { required: true, message: "昵称不能为空", trigger: "change" },
    {
      pattern: nickName,
      message: "昵称由4-16个汉字、字母与数字的任意组合组成",
      trigger: "change",
    },
  ];
  return rule;
}

import axios from "axios";
import qs from "qs";
import { useAuth, useToast } from "../hooks";
import {
  makeRandomId,
  getUUID,
  isMobile,
  isHybridApp,
  getBridgeAppInfo,
  PROJECT_VERSION,
} from "../common";
import { useEvent } from "../config";
export interface Sign {
  [key: string]: string | number;
}
const { getToken, setToken } = useAuth();
const { toast } = useToast();
const { tokenEvent } = useEvent();
const requestMap: string[] = [];
export function requesterFn() {
  const request = axios.create({
    baseURL: window.CONFIG?.api || process.env.CMS_API,
    headers: {
      "content-type": "application/x-www-form-urlencoded",
    },
    timeout: 10 * 1000,
    withCredentials: true,
  });
  request.interceptors.request.use(
    async (config: any) => {
      // 上传文件接口单独处理
      if (config.url === "/api/file/upload") {
        config.headers["content-type"] = "multipart/form-data";
        config.headers = Object.assign(config.headers, await creatHeaders());
        return config;
      }
      const requestParams = `${config.url}${JSON.stringify(config.params)}`;
      if (requestMap.includes(requestParams)) return;
      requestMap.push(requestParams);
      if (config?.method && ["post", "put"].includes(config?.method)) {
        config.data = qs.stringify(config.data, {
          arrayFormat: "indices",
          allowDots: true,
        });
      }
      config.headers = Object.assign(config.headers, await creatHeaders());
      return config;
    },
    () => {
      return Promise.reject({ code: 1, status: 200, data: {}, result: false, message: "请求异常" });
    }
  );
  request.interceptors.response.use(
    response => {
      const requestParams = `${response.config.url}${JSON.stringify(response.config.params)}`;
      requestMap.splice(requestMap.indexOf(requestParams), 1);
      const { data, status } = response;
      const { code } = data || {};
      if ([401, 403, 405].includes(code)) {
        setToken("");
        tokenEvent.emit(true);
        // console.log("request.interceptors");
        // toast.error(data.message);
        return Promise.resolve(Object.assign(data, { result: false }));
      }
      if (typeof data === "string" && data.length > 10 && status === 200) {
        return Promise.resolve(Object.assign({ data }, { result: true, status }));
      } else if (code !== 0) {
        toast.error(data.message);
        Promise.resolve({ ...response, result: false });
      }

      return Promise.resolve(Object.assign(data, { result: code === 0, status }));
    },
    error => {
      const { response, message } = error;
      const requestParams =
        response && `${response?.config.url}${JSON.stringify(response?.config?.params)}`;
      requestMap.splice(requestMap.indexOf(requestParams), 1);
      const { status, data = {} } = response || {};
      if (message.includes("timeout")) {
        return Promise.resolve(
          Object.assign(data ? data : {}, { result: false, message: "请求超时，请稍后再试!" })
        );
      }
      if ([401, 403, 405].includes(status)) {
        setToken("");
        console.log("request.interceptors.tokenEvent");
        tokenEvent.emit(true);
        return Promise.resolve(Object.assign(data, { result: false }));
      }
      if (status >= 500 || status === 400) {
        toast.error(data.message);
        return Promise.resolve({ ...data, result: false });
      }
    }
  );
  return request;
}

export async function creatHeaders() {
  const timestamp = Date.now();
  const sign = makeRandomId(-16);
  const appInfo = isHybridApp() ? await getBridgeAppInfo() : {};
  const {
    version = PROJECT_VERSION,
    device_id = getUUID(),
    client_type = isMobile ? "h5" : "web",
  } = appInfo || {};
  const lang = getLang();
  const signParams: Sign = {
    timestamp,
    sign,
    version,
    client_type,
    device_id,
    lang,
  };
  const token = await getToken();
  if (token) {
    signParams.Authorization = `${token}`;
  }
  return signParams;
}
export function getLang() {
  const lang: string = sessionStorage.getItem("langues") || "zh-CN";
  const LangList: string[] = [
    "zh-CN",
    "en",
    "th",
    "vi",
    "id",
    "zh-CN",
    "en-US",
    "th-TH",
    "vi-VN",
    "in-ID",
  ];
  return LangList[LangList.indexOf(lang) + LangList.length / 2];
}

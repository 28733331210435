import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
dayjs.locale("zh-cn");
import { isIos } from "./browser.util";

export function DayToNumber(day = 0, format = "YYYY-MM-DD") {
  return dayjs().subtract(day, "day").format(format);
}

/*
 * 时间戳转日期
 */
export function timestampToDate(timestamp: number | string) {
  if (!timestamp || timestamp == 0) return "";
  return dayjs(timestamp).format("YYYY-MM-DD");
}

/*
 * 时间戳转日期时间
 */
export function timestampToDateTime(timestamp: number) {
  if (!timestamp || timestamp == 0) return "";
  return dayjs(timestamp).format("YYYY-MM-DD HH:mm:ss");
}

/**
 * 两个日期天数差
 */
export function timesDiffToDays(timestamp: number, startDate: number = new Date().getTime()) {
  if (!timestamp || timestamp == 0) return 0;
  const today = timestampToDate(startDate || 0);
  const registerTime = timestampToDate(timestamp || 0);
  return dayjs(today).diff(dayjs(registerTime), "day");
}

/*
  视频进度条时间格式化
 */
export const secondToTime = (second: number): string => {
  second = second || 0;
  if (second === 0 || second === Infinity || second.toString() === "NaN") {
    return "00:00:00";
  }
  const add0 = (num: any) => (num < 10 ? "0" + num : "" + num);
  const hour = Math.floor(second / 3600);
  const min = Math.floor((second - hour * 3600) / 60);
  const sec = Math.floor(second - hour * 3600 - min * 60);
  return (hour > 0 ? [hour, min, sec] : ["0", min, sec]).map(add0).join(":");
};

// 标准时间转字符串
export const timeToString = (time: string | number) => {
  return dayjs(time).format("YYYY-MM-DD");
};

// 标准时间转字符串
export const dateToTime = (time: string | number) => {
  return dayjs(time).format("HH:mm");
};

/**
 * 时间格式化
 */
export function toTime(time: Date | number | string, format = "YYYY/MM/DD HH:mm:ss") {
  if (!time) return;
  const formatTime = dayjs(time).format(format);
  if (formatTime === "Invalid Date") {
    return time;
  }
  return formatTime;
}

/*
 * 判断是否满足18岁
 * time : 毫秒级时间戳，用户选中日期的时间戳
 * */
export function isAdult(time: number) {
  const nowDate = new Date(); // 当前时间
  const mouth = nowDate.getMonth() + 1;
  let day = nowDate.getDate();
  const year = nowDate.getFullYear() - 18;
  if (mouth === 2 && day >= 28) {
    if ((year % 4 == 0 && year % 100 != 0) || year % 400 == 0) {
      day = 29;
    } else {
      day = 28;
    }
  }

  // 判断安卓&&IOS
  let oldDate;
  if (isIos) {
    oldDate = new Date(year + "/" + mouth + "/" + day).getTime();
  } else {
    oldDate = new Date(year + "-" + mouth + "-" + day).getTime();
  }
  return oldDate < time ? true : false; // true 表示不满18
}

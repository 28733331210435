import { isMobile } from "core";
const pubKey =
  "MIICeAIBADANBgkqhkiG9w0BAQEFAASCAmIwggJeAgEAAoGBANhG1gsaik8tum4s\n" +
  "da79PbKHpqL5eu4PLEGYg8WcIMTqRigeE6goodhqvzFvjkb9vOOgHItEfSPEAT8i\n" +
  "ZlxKWpL0NSfx0BLWyXWLqPcCb0YfM/EZQqBM9Hv89Qs7fE3s7gaJ6w9F+nVekRJl\n" +
  "fC2V9wE8U8fPGG/pV8NLip5GbEqNAgMBAAECgYBcyA69Fras/w4Sg6o81tEoDL+0\n" +
  "/Nf61rGCtn4ZQUBhmatdPIHsSvobkLfS6mucEvB8qkx0hn404Lo0LnrBLvipdoy8\n" +
  "bvVGhoA2gsgwt9YzRpQlygW3q/WeU8ailduFLP0Q1Evj/0kcLZPJ3zm56zdbLdGy\n" +
  "bwbYxgcgFiLhdbvW1QJBAOu83J/KL3rgCHbHKPOCmUQxuV/YprnoJwct89lc8I6P\n" +
  "X4bh6Jcehcbz0Vbb6Ag2PUh9U8ee/mWZUEXLcnPupO8CQQDq3cGqtnvuoWbc0ox4\n" +
  "r2dPXQzmVe/C/Fu4yigWWuMF5h2walS8onw/Q6lS2Qr86PG5Wr6BWTGWGfnx3FlH\n" +
  "uuBDAkEA2wpWucalTXgjhTJTM8jS3tGRHKTzbmRu9zXUkdDQ2LYRngQFGX3L8ewV\n" +
  "RK76r76uPM/u69opKYBGXUPH6j8BUwJBAJmUjvq5v4rIwkGt8KtOT6kG+NN56IHB\n" +
  "kZzhnJsZrZCC7HEa5vQ+8C5SaVvCj7vX+5EwNJjSoWk87UNpynvsSI8CQQCyvqH+\n" +
  "AmkaM4ARGKIL1Qyw+H9l5OM+X5GzFdcNgIQ96r/oap/LsAqDPckT3rqAz8lfeWMq\n" +
  "2KXvQzJasvDX+i9z";
export async function jsencrypt(text: string): Promise<string> {
  const { JSEncrypt } = await import("jsencrypt");
  // @ts-ignore
  const encryptStr = new JSEncrypt();
  encryptStr.setPublicKey(pubKey); // 设置 加密公钥
  // @ts-ignore
  return encryptStr.encrypt(text.toString()); // 进行加密
}
export async function decrypt(word: string, keyStr?: string, ivStr?: string): Promise<string> {
  const { mode, AES, enc, pad } = await import("crypto-js");

  let KEY = enc.Utf8.parse("78bYrWzHD2dwspdq");
  let IV = enc.Utf8.parse("1234567890123456");
  // let KEY = null
  // console.log(isMobile);

  // let IV = enc.Utf8.parse("1234567890123456");
  // if (isMobile) {
  //   KEY = enc.Utf8.parse("78bYrWzHD2dwspdq");
  // } else {
  //   KEY = enc.Utf8.parse("HEKxgrCjzqXNxyaJ");
  // }
  let key = KEY;
  let iv = IV;
  if (keyStr) {
    iv = enc.Utf8.parse(ivStr);
  }

  let base64 = enc.Base64.parse(word);

  let src = enc.Base64.stringify(base64);

  var decrypt = AES.decrypt(src, key, {
    iv: iv,
    mode: mode.CBC,
    padding: pad.ZeroPadding,
  });
  var decryptedStr = decrypt.toString(enc.Utf8);
  return decryptedStr.toString();
}

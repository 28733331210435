import { defineComponent, Transition, computed } from "vue";
import "@kgsport-cms/style";
import { LayoutView } from "commom";
import { AsynAgentComp } from "scaffolds/cms-web-base/agentComponets";
import webAgent from "./views/style/webAgent.module.less";
import { useRoute, useRouter } from "vue-router";
import zhCN from "ant-design-vue/es/locale/zh_CN";
import { ConfigProvider } from "ant-design-vue";
import { useEvent } from "core";
import Footer from "blocks/Footer/src";
export default defineComponent({
  setup() {
    const route = useRoute();
    const isEmptyPage = computed(() => !!route.meta.emptyPage);
    const { onToken } = useEvent();
    const { push } = useRouter();
    onToken(res => {
      if (res) {
        push("/entry/login");
      }
    });
    const Main = () =>
      isEmptyPage.value ? (
        <router-view />
      ) : (
        <LayoutView showPadding={false}>
          <AsynAgentComp name={"HeaderPA"} />
          <div class={webAgent.webAgent}>
            <AsynAgentComp name={"SidePA"} />
            <router-view class={webAgent.webAgentContent} />
          </div>
          <Footer isFootLink={false} />
        </LayoutView>
      );
    return () => (
      <ConfigProvider locale={zhCN}>
        <Transition name="fade" appear>
          <Main />
        </Transition>
      </ConfigProvider>
    );
  },
});

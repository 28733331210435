import TopIcon1 from "../asstes/top-01.png";
import TopIcon2 from "../asstes/top-02.png";
import TopIcon3 from "../asstes/top-03.png";
import TopIcon4 from "../asstes/top-04.png";
import TopIcon5 from "../asstes/top-05.png";
import TopIcon6 from "../asstes/top-06.png";
import TopIcon7 from "../asstes/top-07.png";
import TopIcon8 from "../asstes/top-08.png";
import TopIcon9 from "../asstes/top-09.png";
import TopIcon10 from "../asstes/top-010.png";
import spriteIcon1 from "../asstes/sprite-01.png";
import spriteIcon2 from "../asstes/sprite-02.png";
import spriteIcon3 from "../asstes/sprite-03.png";
// 灰色图标
import TopIconh1 from "../asstes/toph-01.png";
import TopIconh2 from "../asstes/toph-02.png";
import TopIconh3 from "../asstes/toph-03.png";
import TopIconh4 from "../asstes/toph-04.png";
import TopIconh5 from "../asstes/toph-05.png";
import TopIconh6 from "../asstes/toph-06.png";
import TopIconh7 from "../asstes/toph-07.png";
import TopIconh8 from "../asstes/toph-08.png";
import TopIconh9 from "../asstes/toph-09.png";
import TopIconh10 from "../asstes/toph-010.png";
import spriteIconh1 from "../asstes/spriteh-01.png";
import spriteIconh2 from "../asstes/spriteh-02.png";
import spriteIconh3 from "../asstes/spriteh-03.png";

const listTop = [
  {
    icon: TopIcon1,
    iconH: TopIconh1,
  },
  {
    icon: TopIcon2,
    iconH: TopIconh2,
  },
  {
    icon: TopIcon3,
    iconH: TopIconh3,
  },
  {
    icon: TopIcon4,
    iconH: TopIconh4,
  },
  {
    icon: TopIcon5,
    iconH: TopIconh5,
  },
  {
    icon: TopIcon6,
    iconH: TopIconh6,
  },
  {
    icon: TopIcon7,
    iconH: TopIconh7,
  },
  {
    icon: TopIcon8,
    iconH: TopIconh8,
  },
  {
    icon: TopIcon9,
    iconH: TopIconh9,
  },
  {
    icon: TopIcon10,
    iconH: TopIconh10,
  },
];
const sponsor = [
  {
    icon: spriteIcon1,
    iconH: spriteIconh1,
    title: "英国GC监督委员会",
  },
  {
    icon: spriteIcon2,
    iconH: spriteIconh2,
    title: "英属维尔京群岛（BVI）认证",
  },
  {
    icon: spriteIcon3,
    iconH: spriteIconh3,
    title: "英国政府博彩牌照",
  },
];

export { listTop, sponsor };

import { defineComponent, PropType } from "vue";
import layout from "./layout.module.less";
import { useConfigContext } from "core";
const Section = defineComponent({
  setup(_props, { slots }) {
    return () => <div class={layout.layoutSection}>{slots?.default?.()}</div>;
  },
});
const View = defineComponent({
  Section,
  props: {
    showPadding: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    minHeight: {
      type: String as PropType<string | null>,
      default: "100vh",
    },
  },
  setup(props, { slots }) {
    const { config } = useConfigContext();
    return () => (
      <div
        class={[layout.layoutView]}
        style={{
          paddingTop: props.showPadding ? `${config.paddingTop}px` : undefined,
          minHeight: props.minHeight || undefined,
        }}
      >
        {slots?.default?.()}
      </div>
    );
  },
});
export default View as typeof View & {
  readonly Section: typeof Section;
};

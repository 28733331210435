import {
  defineComponent,
  PropType,
  onMounted,
  onBeforeUnmount,
  watch,
  Transition,
  computed,
  provide,
  InjectionKey,
  useAttrs,
  ref,
} from "vue";
import { useScrollLock } from "@vueuse/core";
import modal from "./modal.module.less";
import { useConfig, noon } from "core";
import { svg1 } from "./assets/svgs";
export { modal };
export interface ModalInjectionContext {
  visible: boolean;
  close: () => void;
  update: () => void;
  emit: (...arg: any[]) => void;
}
export const MODAL_INJECTION_KEY: InjectionKey<ModalInjectionContext> = Symbol("modal");
export const ModalContext: ModalInjectionContext = {
  visible: false,
  close: noon,
  update: noon,
  emit: noon,
};
export const ModalWrap = defineComponent({
  props: {
    visible: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    title: {
      type: String as PropType<string>,
      default: "",
    },
    header: {
      type: [Function, Boolean] as PropType<() => void | null | boolean>,
      default: null,
    },
    footer: {
      type: Function as PropType<() => void | null | boolean>,
      default: null,
    },
    content: {
      type: Function as PropType<(props: any) => void | null>,
      default: null,
    },
    onClose: {
      type: Function as PropType<() => void | null>,
      default: null,
    },
    bodyClass: {
      type: String as PropType<string | null>,
      default: null,
    },
    bodyStyle: {
      type: Object as PropType<object | null>,
      default: null,
    },
    closable: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    // 关闭销毁元素
    destroyOnClose: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    modalProps: {
      type: [Object, Array, String, undefined, null] as PropType<
        object | any[] | string | undefined | null
      >,
      default: () => ({}),
    },
    // fade move-up move-down
    transitionName: {
      type: String as PropType<string>,
      default: "fade",
    },
    position: {
      type: String as PropType<string>,
      default: "center",
    },
  },

  inheritAttrs: false,
  emits: ["update:visible", "close"],
  setup(props, { emit, slots }) {
    const isLocked = useScrollLock(document.body);
    const { isPc } = useConfig();
    const attr: any = useAttrs();
    const destroy = ref(true);
    provide(MODAL_INJECTION_KEY, {
      close: attr.close,
      update: attr.update,
      visible: props.visible,
      emit,
    });
    const onclose = () => {
      emit("update:visible", false);
      if (props.onClose) {
        props.onClose();
      }
      isLocked.value = false;
    };
    const classMap: { [key: string]: string } = {
      bottom: modal.bottom,
      center: modal.center,
    };
    const rootClass = computed<string>(() => classMap[props.position] as string);
    onMounted(() => {
      isLocked.value = true;
    });
    onBeforeUnmount(() => {
      isLocked.value = false;
    });
    watch(
      () => props.visible,
      () => {
        if (props.visible === false) {
          isLocked.value = false;
          if (props.destroyOnClose) {
            destroy.value = false;
          }
        } else {
          if (props.destroyOnClose) {
            destroy.value = true;
          }
          isLocked.value = true;
        }
      }
    );
    const renderHeader = () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (props.header === false || props.header === null) return null;
      return (
        <div class={[modal.modalHeader, !isPc.value && modal.modalHeaderMobile]}>
          <span class={modal.modalHeaderLeft} />
          <div class={modal.modalHeaderTitle}>
            {props.title || (props.modalProps as any)?.title}
          </div>
          {isPc.value ? (
            <span v-html={svg1} class={modal.modalHeaderClose} onClick={onclose}></span>
          ) : (
            <div class={modal.modalHeaderDiv} onClick={onclose}>
              <span></span>
            </div>
          )}
        </div>
      );
    };
    const renderContent = () => {
      if (props.content) {
        return destroy.value ? props.content(props.modalProps) : null;
      } else {
        return slots?.default?.(props.modalProps);
      }
    };
    return () => (
      <div class={[modal.modal, rootClass.value]} v-show={props.visible}>
        <Transition name="fade" appear>
          <div onClick={onclose} v-show={props.visible} class={[modal.modalMask]} />
        </Transition>
        <Transition name={props.transitionName} appear>
          <div
            v-show={props.visible}
            style={props.bodyStyle || {}}
            class={[props.bodyClass, isPc.value ? modal.pc : null, modal.modalContent]}
          >
            {renderHeader()}
            {renderContent()}
          </div>
        </Transition>
      </div>
    );
  },
});

/*
 判断视频链接属于那种媒体类型  hls  flv dash  normal
 */
export const getMediaType = (url: string) => {
  if (/m3u8(#|\?|$)/i.exec(url)) {
    return "hls";
  } else if (/.flv(#|\?|$)/i.exec(url)) {
    return "flv";
  } else if (/.mpd(#|\?|$)/i.exec(url)) {
    return "dash";
  } else {
    return "normal";
  }
};

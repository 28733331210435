/**
 * 1、获取钱包余额
 * 2、总余额(不包括冻结金额)、冻结金额
 * 3、一键回收其他游戏钱包余额
 */
import { computed, reactive } from "vue";
import { Api } from "../services";
import { WithdrawSchema, ReclaimAmount } from "@kgsport-cms/service";
import { useToast } from "./useToast.hook";
import { useConfig, userRecord, mathAdd, currency } from "core";
import { debounce } from "lodash-es";

export interface WalletData {
  lotteryData: WithdrawSchema;
  downFlag: boolean;
  tabIndex: number;
  reclaimloading: boolean;
}

const store = reactive<WalletData>({
  // 钱包明细
  lotteryData: {
    balance: 0,
    freeze: 0,
    gameBalance: [],
    gameTotal: 0,
  },
  downFlag: true,
  tabIndex: 1,
  reclaimloading: false,
});

export function useWallet() {
  const { toast } = useToast();
  const { delayTimeout } = useConfig();
  const { getquotaList } = userRecord();

  const venueList = computed(() => store.lotteryData.gameBalance);
  // 冻结金额
  const freeze = computed(() => store.lotteryData.freeze);

  // 游戏内总余额
  const gameTotal = computed(() => store.lotteryData.gameTotal);

  // 中心钱包余额
  const balance = computed(() => store.lotteryData.balance);
  // 总余额
  const AllBalance = computed(() =>
    currency(
      mathAdd(store.lotteryData.balance)
        .plus(store.lotteryData.freeze)
        .plus(store.lotteryData.gameTotal)
        .toNumber()
    )
  );
  // 总余额(不包括冻结金额)
  const availableBalance = computed(() =>
    mathAdd(store.lotteryData.balance).plus(store.lotteryData.gameTotal).toNumber()
  );
  const reclaimloading = computed(() => store.reclaimloading);
  const changeDown = () => {
    store.downFlag = !store.downFlag;
  };
  const tabsClick = (index: number) => {
    store.tabIndex = index;
  };
  // 获取钱包
  const platformBalance = async () => {
    try {
      store.reclaimloading = true;
      const { data, result } = await Api.UserService.platformBalance();
      store.reclaimloading = false;
      if (result) {
        store.lotteryData = data;
      }
    } catch (e) {
      console.error("ERROR:", e);
    }
  };
  const reclaimAmountUpdata = async () => {
    const { result, message } = await Api.GameService.reclaimAmount();
    if (result) {
      return toast.success("回收成功");
    }
    return toast.error(message);
  };
  // 一键回收其他游戏钱包余额
  const reclaimAmount = async () => {
    try {
      store.reclaimloading = true;
      const { result, data, message } = await Api.GameService.reclaimAmount();
      store.reclaimloading = false;
      if (result) {
        setTimeout(() => {
          platformBalance();
        }, delayTimeout.value);
        getquotaList();
        return data.some((item: ReclaimAmount) => item.status === 0)
          ? toast.success("回收成功")
          : toast.error("游戏余额回收失败");
      }
      return toast.error(message);
    } catch (e) {
      console.log(e, "eee");
    }
  };
  return {
    freeze,
    balance,
    AllBalance,
    availableBalance,
    venueList,
    reclaimloading,
    store,
    gameTotal,
    changeDown,
    tabsClick,
    platformBalance,
    reclaimAmount: debounce(reclaimAmount, 1000),
    reclaimAmountUpdata: debounce(reclaimAmountUpdata, 1000),
  };
}
export function useSetWallet() {
  return {
    store,
  };
}

import { computed, reactive, watchEffect, nextTick } from "vue";
import { Form } from "ant-design-vue";
import { UserInfoSchema } from "@kgsport-cms/service";
import { useDebounceFn } from "@vueuse/core";
import {
  Api,
  useToast,
  isAdult,
  useAuth,
  jsencrypt,
  passwordRule,
  useCountDown,
  timesDiffToDays,
  useModalContext,
  useConfigContext,
  timestampToDate,
  phoneNumberRule,
  withdrawPassRule,
  confirmPasswordRule,
  verificationCodeRule,
  confirmNewAndOldPasswordRule,
  nicknameRule,
} from "core";

import { Captcha } from "commom";
import { useRouter } from "vue-router";

export interface UserStore {
  user: null | UserInfoSchema;
}

export interface UserForm {
  account: string;
  birthday: string;
  email: string;
  nickname: string;
  sex: string;
  realName: string;
  avatar: string;
}

export interface UserPhoneForm {
  phone: string;
  code: string;
}

export interface UserPassForm {
  newPassword: string;
  rawPassword2: string;
  rawPassword: string;
}

export interface smsCodeParams {
  type: number;
  phone: string;
  dialCode: string;
}

export interface UserWithdrawForm {
  withdrawPassword: string;
}

export interface headerItem {
  value: number;
  src: string;
}

const store = reactive<UserStore>({
  user: null,
});
const useForm = Form.useForm;

export function useUser() {
  const { count, start } = useCountDown();
  const { isLogin, setToken } = useAuth();
  const popupModel = useModalContext();
  const { config } = useConfigContext();

  // 个人中心修改表单
  const form = reactive<UserForm>({
    account: "",
    birthday: "",
    email: "",
    nickname: "",
    sex: "0",
    realName: "",
    avatar: "",
  });
  const formRule = reactive({
    birthday: [],
    email: [],
    nickname: nicknameRule(),
    sex: [],
    avatar: [],
    realName: [],
  });
  // 修改手机号
  const phoneForm = reactive({
    dialCode: "", //国际区号
    phone: "", // 手机号码
    smsCode: "", // 短信验证码
  });
  // 手机号/验证码 验证规则
  const phoneFormRules = reactive({
    phone: phoneNumberRule(), // 手机号码
    smsCode: verificationCodeRule(), // 短信验证码
  });
  // 修改密码
  const passForm = reactive({
    rawPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  // 修改密码验证规则
  const passFormRules = reactive({
    rawPassword: passwordRule(), // 旧密码
    newPassword: [...passwordRule(), ...confirmNewAndOldPasswordRule(passForm, "rawPassword")], // 新密码
    confirmPassword: [...passwordRule(), ...confirmPasswordRule(passForm, "newPassword")], // 确认新密码
  });
  // 修改提现
  const withdrawForm = reactive({
    withdrawPassword: "",
    confirmWithdrawPassword: "",
  });
  // 修改提现密码验证规则
  const withdrawFormRules = reactive({
    withdrawPassword: withdrawPassRule(),
    confirmWithdrawPassword: [
      ...withdrawPassRule(),
      ...confirmPasswordRule(withdrawForm, "withdrawPassword"),
    ],
  });
  // 二级密码
  const protectWithdrawForm = reactive({
    dialCode: "",
    phone: "",
    smsCode: "",
  });
  // 二级密码验证规则
  const protectWithdrawFormRules = reactive({
    smsCode: verificationCodeRule(),
  });
  // 基础资料验证规则暴露
  const userAccount = useForm(form, formRule);
  // 修改手机验证规则暴露
  const phoneAccount = useForm(phoneForm, phoneFormRules);
  // 修改账号密码验证规则暴露
  const passAccount = useForm(passForm, passFormRules);
  // 提现密码验证规格暴露
  const withdrawAccount = useForm(withdrawForm, withdrawFormRules);
  // 二级密码验证规格暴露
  const protectAccount = useForm(protectWithdrawForm, protectWithdrawFormRules);

  const user = computed<UserInfoSchema | null>(() => store.user);
  //注册时间
  const registerTime = computed(() => timestampToDate(user.value?.registerTime || 0));
  // 注册天数
  const registerDate = computed(() =>
    user.value?.registerTime ? timesDiffToDays(user.value?.registerTime) + 1 : 0
  );
  // 是否绑定手机号
  const isPhone = computed<boolean>(() => !!user.value?.phone);
  // 是否绑定邮箱
  const isEmail = computed<boolean>(() => !!user.value?.email);
  // 是否开启手机号提现验证
  const isWithdrawPhone = computed<boolean>(() => !!user.value?.email);
  // 是否开启提现密码验证
  const isWithdrawPwd = computed<boolean>(() => !!user.value?.hasWithdrawPwd);

  const { toast } = useToast();
  const router = useRouter();

  // 初始化数据
  const stopWatchEffect = watchEffect(() => {
    if (user.value?.account) {
      nextTick(() => {
        updateData();
        stopWatchEffect();
      });
    }
  });

  const updateData = () => {
    if (user.value) {
      Object.keys(form).forEach((item: string) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        form[item] = (user.value as any)[item];
      });
    }
  };
  // 暴露获取用户详情方法
  const getUserInfo = async (isCache = true) => {
    if ((isCache && store.user) || !isLogin.value) return;
    try {
      const { data, result, code } = await Api.UserService.userInfo();
      if (code === 401) {
        setToken("");
        return router.push("/entry/login");
      }
      if (!result) return;
      if (data) store.user = data;
    } catch (e) {
      console.error(e);
    }
  };
  // 个人信息
  const editSubmit = async () => {
    try {
      if (isAdult(new Date(form.birthday).getTime())) {
        toast.warning("生日不能设置小于18周岁！");
        return;
      }
      await userAccount.validate();

      const { message, result } = await Api.UserService.editUser(form);
      if (!result) {
        toast.error(message);
        return;
      }
      toast.success("修改成功");
      await getUserInfo(false);
    } catch (e) {
      console.log(e, "e");
    }
  };
  // 发送短信验证码
  const getPhoneInfoCode = async (params: smsCodeParams) => {
    try {
      const { result, message } = await Api.UserService.getPhoneInfoCode(params);
      if (!result) {
        toast.error(message);
        return;
      }
      start(60);
    } catch (e) {
      console.error(e);
    }
  };
  // 发送短信验证码(已绑定手机号，发送到绑定手机号，无需传递手机号码)
  const getSmsCode = async (params: { type: number }) => {
    try {
      const { result, message } = await Api.UserService.getSmsCode(params);
      if (!result) {
        toast.error(message);
        return;
      }
      start(60);
    } catch (e) {
      console.error(e);
    }
  };
  // 个人手机号
  const editPhoneSubmit = async () => {
    try {
      await phoneAccount.validate();
      const { message, result } = await Api.UserService.bindPhone(phoneForm);
      if (!result) {
        toast.error(message);
        return;
      }
      toast.success("修改成功");
      await getUserInfo(false);
      popupModel?.close();
    } catch (e) {
      console.log("editPhoneSubmit", e);
    }
  };
  // 点击发送验证码先验证手机号不为空 再弹出极验
  const sendCodeSubmit = async () => {
    if (!phoneForm.phone) {
      toast.error("手机号码不能为空");
      return;
    }
    openCaptcha({
      type: 5,
      phone: phoneForm.phone as string,
      dialCode: phoneForm.dialCode as string,
    });
  };
  // 个人密码
  const editPassSubmit = async () => {
    try {
      await passAccount.validate();
      const info = {
        rawPassword: await jsencrypt(passForm.rawPassword),
        newPassword: await jsencrypt(passForm.newPassword),
        confirmPassword: await jsencrypt(passForm.confirmPassword),
      };
      const { message, result } = await Api.UserService.updatePassword(info);
      if (!result) {
        toast.error(message);
        return;
      }
      toast.success("修改成功");
      await getUserInfo(false);
      popupModel?.close();
    } catch (e) {
      console.log("editPhoneSubmit", e);
    }
  };
  // 个人提现密码
  const editWithdrawSubmit = async () => {
    try {
      await withdrawAccount.validate();
      const info = {
        withdrawPwd: withdrawForm.withdrawPassword,
      };
      const { message, result } = await Api.UserService.updateWithdrawPwd(info);
      if (!result) {
        toast.error(message);
        return;
      }
      toast.success("设置成功");
      await getUserInfo(false);
      popupModel?.close();
    } catch (e) {
      console.log("editPhoneSubmit", e);
    }
  };
  // 二级密码
  const editProtectWithdrawSubmit = async () => {
    try {
      await protectAccount.validate();
      const { message, result } = await Api.UserService.protectWithdraw(protectWithdrawForm);
      if (!result) {
        toast.error(message);
        return;
      }
      toast.success("修改成功");
      await getUserInfo(false);
      popupModel?.close();
    } catch (e) {
      console.log("editProtectWithdrawSubmit", e);
    }
  };

  const openCaptcha = async (smsParams: smsCodeParams) => {
    if (count.value !== 0) return;
    // TODO 验证器开关  目前只支持极验
    if (!config.captchaType) {
      await getPhoneInfoCode(smsParams);
      return;
    }
    // 配置了验证器
    const prams = { name: config.captchaType, clientType: "web" };
    const { data, result } = await Api.CommonService.captchaCode(prams);
    if (result) {
      const captcha = new Captcha({
        type: config.captchaType,
        config: {
          gt: data.gt,
          challenge: data.challenge,
        },
        onSuccess: async (capPrams: any) => {
          const cPams: any = {};
          cPams.geetest_seccode = capPrams.geetest_seccode;
          cPams.geetest_validate = capPrams.geetest_validate;
          cPams.geetest_challenge = capPrams.geetest_challenge;
          await getPhoneInfoCode({ ...smsParams, ...cPams });
        },
      });
      await captcha.open();
    }
  };

  // 提交头像选择，此方法仅参入头像参数
  const avatarAction = async () => {
    const { message, result } = await Api.UserService.editUser({ avatar: form.avatar });
    if (!result) {
      toast.error(message);
      return;
    }
    toast.success("头像保存成功");
    await getUserInfo(false);
  };

  const editDebounceSubmit = useDebounceFn(editSubmit, 500);
  const avatarSubmit = useDebounceFn(avatarAction, 500);

  // 基础资料性别选择
  const checkSex = (index: string) => {
    form.sex = index;
  };

  // 用户头像选择
  const selectHeader = async (item: headerItem) => {
    form.avatar = String(item.value);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // store.user.avatar = form.avatar;
  };

  // 确认头像选择
  const submitAvatar = async () => {
    await userAccount.clearValidate();
    await avatarSubmit();
  };
  return {
    form,
    phoneForm,
    passForm,
    withdrawForm,
    user,
    registerTime,
    registerDate,
    userAccount,
    phoneAccount,
    protectAccount,
    protectWithdrawForm,
    protectWithdrawFormRules,
    passAccount,
    withdrawAccount,
    count,
    isPhone,
    isEmail,
    isWithdrawPhone,
    isWithdrawPwd,
    editDebounceSubmit,
    getUserInfo,
    checkSex,
    getPhoneInfoCode,
    getSmsCode,
    editPhoneSubmit,
    editPassSubmit,
    selectHeader,
    editWithdrawSubmit,
    editProtectWithdrawSubmit,
    openCaptcha,
    sendCodeSubmit,
    submitAvatar,
  };
}
export function useSetUser() {
  return {
    store,
  };
}

import { defineComponent, toRefs } from "vue";
import { useQRCode } from "@vueuse/integrations/useQRCode";
import style from "./qrcode.module.less";

export default defineComponent({
  name: "QRcode",
  props: {
    text: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const { text } = toRefs(props);
    const qrcode = useQRCode(text, {
      errorCorrectionLevel: "H",
      margin: 0,
    });

    return () => <img class={style.qrCode} src={qrcode.value} alt="QR Code" />;
  },
});

import { isMobile } from "../utils";
// 目前web 和h5 token无法共用 需要根据当前环境 区分token
export const STORE_TOKEN = `ACCESS_TOKEN_${isMobile ? "H5" : "WEB"}`;

// 代理
export const STORE_AGENT = `ACCESS_AGENT_${isMobile ? "H5" : "WEB"}`;
// rc 推广码
export const STORE_RC = `CMS_RC`;

// 全局配置
export const STORE_GLOBEL_CONFIGS = `CMS_GLOBEL_CONFIGS`;

// H5消息类型
export const MESSAGE_ACTIVE_H5 = `CMS_MESSAGE_ACTIVE_H5`;

// PC消息类型
export const MESSAGE_ACTIVE_PC = `CMS_MESSAGE_ACTIVE_PC`;

// 会员验证信息
export const STORE_USER_REMEMBER = `CMS_USER_REMEMBER`;

// 充值弹窗是否提醒
export const RECHARGE_MODAL_SHOW = `CMS_RECHARGE_MODAL_SHOW`;

// 线路弹窗
export const STORE_LINE_POPUP = `CMS_LINE_POPUP`;
// 线路弹窗
export const STORE_ACTIVITY_POPUP = `CMS_ACTIVITY_POPUP`;
export const STORE_PERSONAL_POPUP = `CMS_PERSONAL_POPUP`;
// 当前项目版本
export const PROJECT_VERSION = `4.0.0.0`;
export const STORE_COOKIE_RC = `rcCode`;
export const STORE_COOKIE_PROXY = `proxy`;

// 套壳APP 影藏顶部下载
export const STORE_DOWNLOAD = `CMS_DOWNLOAD`;

import { defineComponent, PropType, ref, reactive } from "vue";
import lazy from "commom/Lazyload/";
import image from "./image.module.less";
import { useConfigContext } from "core";

//const cache = new Set();
export default defineComponent({
  directives: {
    lazy,
  },
  name: "Image",
  props: {
    src: {
      type: [String, null, undefined, Object] as PropType<string | null | undefined>,
      required: true,
    },
    type: {
      type: String as PropType<string>,
      default: "img",
    },
    background: {
      type: String as PropType<string>,
    },
    fit: {
      type: String as PropType<string | null>,
      default: "cover",
    },
  },
  setup(props) {
    const { tsx } = useConfigContext();
    const target = ref<HTMLDivElement>();
    const style = reactive<any>({
      position: "absolute",
      display: "blcok",
      objectFit: props.fit,
      width: 0,
      height: 0,
      minWidth: "100%",
      maxWidth: "100%",
      minHeight: "100%",
      maxHeight: "100%",
      // filter: "blur(20px)",
      zIndex: 0,
      background: props.background,
    });
    const att = reactive({
      width: 0,
      height: 0,
      dataLoad: "loaded",
    });
    return () => (
      <div
        class={[image.image, props.type == "teamLogo" ? image.team : ""]}
        data-load={att.dataLoad}
        ref={target}
      >
        <img
          width={att.width}
          height={att.height}
          v-lazy={{
            src: props.src,
            error: tsx.noData.cms_noimg,
            loading: tsx.noData.cms_noimg,
          }}
          style={style}
          decoding="async"
          alt=""
        />
      </div>
    );
  },
});

import { computed, ComputedRef, inject, reactive, shallowReadonly, ShallowReactive } from "vue";
import { IDialCode, DomaiType, IuserCard, IMobileTabBar } from "@kgsport-cms/service";
import {
  unique,
  useAuth,
  Api,
  dialCodeZero,
  isMobile,
  useRuntime,
  STORE_RC,
  decrypt,
  getCookie,
  STORE_COOKIE_RC,
  STORE_COOKIE_PROXY,
  STORE_DOWNLOAD,
} from "core";
import { useUrlSearchParams, useStorage, RemovableRef, useDebounceFn } from "@vueuse/core";
import { CaptchaType, formatCaptchaType, CONFIG_PROVIDER_KEY } from "commom";
import {
  mobileGameScence,
  mobileTabBar,
  mobileUserCard,
  vipIcon,
  RechargeInfoTips,
} from "./default.config";

export interface agentContactType {
  contact: string;
  contactDetail: string;
  contactLogo: string;
  contactType: string;
  id: number;
}

export interface ConfigStore {
  /**
   * 国际区号
   */
  AreaCode: IDialCode[];
  /* 默认国际区号 */
  defaultDialCode: string;
  // 聊天室路径
  chat: string;
  // 代理路径
  agent: string;
  // h5代理路径
  h5agent: string;
  isEnableSign: boolean;
  tsx: Record<string, any>;
  config: Record<string, any>;
  themeColor: string;
  isUserBankBindCount: number;
  isUserVirtualBindCount: number;
  appSpreadDomain: string;
  isEnableVip: boolean;
  isOpenProxy: boolean;
  smsEnable: boolean;
  // 安全设置 - 手机号绑定
  smsSwitch: boolean;
  /* 代理联系方式 */
  agentContact: agentContactType[] | null;
  cpChatEnable: boolean;
  amountShowType: GECMAP;
  customerUrl: string;
  messageUnReadCount: number;
  indexSelfPopupStatus: boolean;
  indexActivityPopupStatus: boolean;
  captchaType: CaptchaType | null;
  // 用于全局 header 高度
  paddingTop: number;
  linePopup: boolean;
  activityPopup: boolean;
  personalPopup: boolean;
  domainList: DomaiType | null;
  // 是否显示h5首页卡片
  isshowMobileUserCard: string;
  // 用户卡片
  mobileUserCard: IuserCard[];
  // h5 底部导航栏tabbar
  mobileTabBar: IMobileTabBar[];
  // h5 游戏列表场景
  mobileGameScence: string;
  // h5 用户卡片vip icon
  vipIcon: string;
  // PC 导航游戏列表场景
  navScene: string;
  RechargeInfoTips: string;
  // 借呗开关
  loanEnable: boolean;
  // 余额宝开关
  yubaoEnable: boolean;
  // 个人中心-是否显示意见反馈
  isShowFeedBack: string;
}

export interface ConfigProvider {
  sitename: ComputedRef<string>;
  config: ShallowReactive<ConfigStore>;
  isPc: ComputedRef<boolean>;
  customer: ComputedRef<string>;
  download: ComputedRef<string>;
  pcSite: ComputedRef<string>;
  h5Site: ComputedRef<string>;
  areaCodeList: ComputedRef<IDialCode[]>;
  defaultCode: ComputedRef<string>;
  delayTimeout: ComputedRef<number>;
  rccode: RemovableRef<string>;
  isEnableVip: ComputedRef<boolean>;
  agentContact: ComputedRef<agentContactType[] | null>;
  cpChatEnable: ComputedRef<boolean>;
  amountShowType: ComputedRef<GECMAP>;
  messageUnReadCount: ComputedRef<number>;
  getDialCode: () => Promise<any[] | undefined>;
  goService: () => void;
  goAgent: () => void;
  goChat: () => Promise<Window | null | undefined>;
  getApi: () => string;
  setSystemConfigs: () => Promise<void>;
  updateMessageCount: (value: number) => void;
  tsx: Record<string, any>;
  updateHeaderTop: (value: number | undefined) => void;
  setDomain: () => void;
  isHideDownlod: boolean;
  yuebao: ComputedRef<boolean>;
  loan: ComputedRef<boolean>;
}

export enum GECMAP {
  // 中国
  CH = "￥",
  // 香港
  HK = "HKD",
  // 印尼
  ID = "₨",
  // 新加坡
  SN = "SGD",
  // 泰国
  TH = "฿",
  // 美国
  US = "$",
  // 越南
  VM = "₫",
}

const store = reactive<ConfigStore>({
  AreaCode: [],
  defaultDialCode: "",
  chat: "/static/chat/",
  agent: "/static/agent/",
  h5agent: "/static/agenth5/",
  config: window.CONFIG || {},
  isEnableSign: true,
  tsx: window.cms_common ? window.cms_common.resource?.tsx : window.top?.cms_common.resource?.tsx, // 特殊处理当前视图在ifram里面情况
  themeColor: "default", // 主题
  isUserBankBindCount: 0, // 银行卡绑定数量
  isUserVirtualBindCount: 0, // 虚拟账户绑定数量
  appSpreadDomain: "", // app推广域名
  isEnableVip: true, // vip显示
  isOpenProxy: true, // 是否开启代理中心
  amountShowType: GECMAP.CH, // 金额显示类型
  smsEnable: true,
  customerUrl: "",
  agentContact: null,
  cpChatEnable: false,
  messageUnReadCount: 0, // 消息未读数量
  indexSelfPopupStatus: false, // 个人弹窗
  indexActivityPopupStatus: false, // 活动弹窗
  captchaType: CaptchaType.GEETEST, // TODO 缺省 验证器类型
  smsSwitch: true,
  paddingTop: 90,
  linePopup: true, // 线路弹窗开关
  activityPopup: true, //活动弹窗开关
  personalPopup: true, //个人弹窗开关
  domainList: null, // 线路
  navScene: window.CONFIG?.navScene || "ScenesKg",
  isshowMobileUserCard: window.CONFIG?.isshowMobileUserCard || "show",
  // 用户卡片
  mobileUserCard: window.CONFIG?.mobileUserCard || mobileUserCard,
  // h5 底部导航栏tabbar
  mobileTabBar: window.CONFIG?.mobileTabBar || mobileTabBar,
  // h5 游戏列表场景
  mobileGameScence: window.CONFIG?.mobileGameScence || mobileGameScence,
  // h5 用户卡片vip icon
  vipIcon: window.CONFIG?.vipIcon || vipIcon,
  RechargeInfoTips: window.CONFIG?.RechargeInfoTips || RechargeInfoTips,
  loanEnable: false,
  yubaoEnable: false,
  isShowFeedBack: window.CONFIG?.isShowFeedBack || "hide",
});

const { getToken } = useAuth();
const params = useUrlSearchParams<Record<string, any>>("history");
// app 套壳影藏h5 下载提示
const isHideDownlod =
  localStorage.getItem(STORE_DOWNLOAD) === "1" || Object.keys(params).includes("downlodTip");
const rccode = useStorage<string>(STORE_RC, params.rc || "");
rccode.value = params.rc || getCookie(STORE_COOKIE_RC) || getCookie(STORE_COOKIE_PROXY);
if (isHideDownlod && !localStorage.getItem(STORE_DOWNLOAD)) {
  localStorage.setItem(STORE_DOWNLOAD, "1");
}
export function useConfig(): ConfigProvider {
  //站点名称
  const sitename = computed(() => window.CONFIG?.title || store.config.sitename || "");
  const isPc = computed(() => !isMobile);
  // 客服链接
  const customer = computed(() => store.customerUrl);
  // 下载链接
  const download = computed(() => window.location.origin + "/m/download");

  // TODO 临时用链接 有接口支持改为列表
  const pcSite = computed(() => window.location.origin);
  const h5Site = computed(() => window.location.origin + "/m");
  // 国际区号列表
  const areaCodeList = computed<IDialCode[]>(() => store.AreaCode);
  // 默认国际区号
  const defaultCode = computed<string>(() => store.defaultDialCode);

  const messageUnReadCount = computed<number>(() => store.messageUnReadCount);
  const isEnableVip = computed<boolean>(() => store.isEnableVip);
  const cpChatEnable = computed<boolean>(() => store.cpChatEnable);
  const agentContact = computed(() => store.agentContact);
  const amountShowType = computed<GECMAP>(() => store.amountShowType);
  // 余额宝开关
  const yuebao = computed<boolean>(() => store.yubaoEnable);
  // 借呗开关
  const loan = computed<boolean>(() => store.loanEnable);
  // 获取API
  const getApi = () => window.CONFIG?.api || process.env.CMS_API || "";

  // 获取手机国际区号
  const getDialCode = async () => {
    try {
      const { data, result } = await Api.ConfigService.dialCode();
      if (result) {
        const dataJson = data.list.map((e: any) => {
          e.code = dialCodeZero(e.code);
          return e;
        });
        // 数组去重 后台返回了重复的数据，重复数据会导致下拉选框组件出问题
        store.AreaCode = unique(dataJson, "code");
        const defaultCode = dataJson.find((item: IDialCode) => Number(item.isDefault) === 1);
        defaultCode && (store.defaultDialCode = defaultCode.code);
        return dataJson;
      }
    } catch (e) {
      console.log(e);
    }
  };

  // 延迟调用接口时间，
  const delayTimeout = computed(() => 1000);
  const goService = () => window.open(customer.value);
  const goAgent = () => {
    window.location.href = store.agent;
  };
  const goChat = async () => {
    const { result } = await Api.GameService.transfer({ platformCode: "KGNL" });
    if (result) {
      return window.open(
        `${
          store.chat
        }#/?isPopup=true&tenantCode=${null}&token=${await getToken()}&tokenKey=${null}&osType=0`,
        "CNN_WindowName",
        "height=812, width=375, top=0,left=0, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=no, status=no"
      );
    }
  };
  const updateHeaderTop = (value: number | undefined) => {
    if (!store.paddingTop) return;
    if (typeof value === "number") {
      store.paddingTop = value;
    }
  };
  const updateMessageCount = (value: number) => {
    store.messageUnReadCount = value;
  };

  const setSystemConfigs = async () => {
    try {
      const { data, result } = await Api.ConfigService.systemConfig();
      if (result) {
        store.isUserBankBindCount = Number(data.isUserBankBindCount);
        store.isUserVirtualBindCount = Number(data.isUserVirtualBindCount);
        store.appSpreadDomain = data.appSpreadDomain;
        store.isEnableVip = Number(data.isEnableVip) === 1;
        store.isOpenProxy = Number(data.isOpenProxy) === 1;
        store.amountShowType = GECMAP.CH; // TODO 文档未知类型 待确认
        store.smsEnable = Number(data.smsEnable) === 1;
        store.customerUrl = data.customerUrl || "";
        store.agentContact = data.agentContacts || null;
        store.cpChatEnable = Number(data.cpChatEnable) === 1;
        store.isEnableSign = Number(data.isEnableSign) === 1;
        store.captchaType = formatCaptchaType(data);
        store.smsSwitch = Number(data.smsSwitch) === 1;
        store.loanEnable = data.loanEnable;
        store.yubaoEnable = data.yubaoEnable;
      }
    } catch (error) {
      console.error(error);
    }
  };
  // 获取域名
  const setDomain = async () => {
    if (store.domainList) return;
    try {
      const { result, data } = await Api.ConfigService.getDomainList();
      if (result) {
        const domain = JSON.parse(await decrypt(data)) as DomaiType;
        store.domainList = domain || {};
      }
    } catch (e) {
      console.log("e:" + e);
    }
  };

  const tsx = store.tsx;

  return {
    // 只读对象
    config: shallowReadonly(store),
    isPc,
    pcSite,
    h5Site,
    customer,
    download,
    defaultCode,
    areaCodeList,
    delayTimeout,
    rccode,
    messageUnReadCount,
    isEnableVip,
    agentContact,
    cpChatEnable,
    tsx,
    sitename,
    amountShowType,
    getDialCode,
    goService,
    goAgent,
    goChat,
    getApi,
    setSystemConfigs,
    updateMessageCount,
    updateHeaderTop,
    setDomain: useDebounceFn(setDomain, 1000, { maxWait: 1000 }),
    isHideDownlod,
    yuebao,
    loan,
  };
}

export function useProduction() {
  const { __CMS_IFRAME__, isEdit } = useRuntime();
  const runtime = computed<boolean>(() => {
    if (__CMS_IFRAME__ || isEdit) return false;
    return true;
  });
  return {
    runtime,
  };
}

// 获取AppConfigProvider的数据
export function useConfigContext() {
  return inject<ConfigProvider>(CONFIG_PROVIDER_KEY, () => useConfig(), true);
}

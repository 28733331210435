import { computed, reactive, ComputedRef, watchEffect } from "vue";
import { Api, toTime, useConfig } from "core";
import { useToast } from "./useToast.hook";
import {
  TranType,
  ReportItem,
  TranRecords,
  TranItem,
  WithdrawData,
  RechargerData,
  RecordItem,
  QuotaItem,
} from "@kgsport-cms/service";
import { useRoute } from "vue-router";
const { toast } = useToast();
import dayjs from "dayjs";

interface options {
  value: string;
  label: string;
}

interface BetDetail {
  betAmount: number;
  betCount: number;
  validAmount: number;
  winAmount: number;
}
interface TransStore {
  data: TranRecords | null;
  billList: TranItem[]; //账变记录
  betList: ReportItem[]; //投注记录
  typeList: TranType[]; //账变类型
  date: options[]; // 日期选项
  recordType: string;
  // gameList: KindItem[]; //游戏列表
  transList: QuotaItem[]; // 转账记录
  withdrawList: WithdrawData | null;
  rechargerList: RechargerData | null;
  betDetail: BetDetail | null;
  day: string;
  beginTime: number;
  endTime: number;
  loading: boolean;
  pagination: {
    total: number;
    current: number;
    pageSize: number;
    pages: number;
  };
}

const store = reactive<TransStore>({
  data: null,
  billList: [],
  betList: [],
  typeList: [],
  transList: [],
  betDetail: null,
  // gameList: [],
  withdrawList: null,
  rechargerList: null,
  date: [
    { value: "0", label: "今日" },
    { value: "1", label: "昨日" },
    { value: "7", label: "近7日" },
    { value: "30", label: "近30日" },
  ],
  recordType: "0",
  day: "0",
  beginTime: dayjs().startOf("D").valueOf(), // 今天00:00:00
  endTime: dayjs().endOf("D").valueOf(), // 今天23:59:59
  loading: false,
  pagination: {
    total: 0,
    current: 1,
    pageSize: 20,
    pages: 0,
  },
});

export const dateOptions = [
  {
    value: "0",
    label: "今日",
    startTime: dayjs().startOf("D").valueOf(),
    endTime: dayjs().endOf("D").valueOf(),
  },
  {
    value: "1",
    label: "昨日",
    startTime: dayjs().subtract(1, "day").startOf("D").valueOf(),
    endTime: dayjs().subtract(1, "day").endOf("D").valueOf(),
  },
  {
    value: "6",
    label: "近7日",
    startTime: dayjs().subtract(7, "day").endOf("D").valueOf(),
    endTime: dayjs().valueOf(),
  },
];

const transStatus: { [key: string]: string } = {
  "1": "转入失败",
  "2": "转出失败",
  "3": "受理成功",
  "4": "已取消",
};
const transferType: { [key: string]: string } = {
  "1": "转出",
  "2": "转入",
};
const withdrawStatus: { [key: string]: string } = {
  "1": "未受理",
  "2": "受理中",
  "3": "已出款",
  "4": "已取消",
  "5": "已拒绝",
  "6": "已撤销",
};

const statusDot: { [key: string]: any } = {
  "1": "default",
  "2": "processing",
  "3": "success",
  "4": "warning",
  "5": "error",
  "6": "warning",
};

const rechargeStatus: { [key: string]: string } = {
  "1": "未受理",
  "2": "受理中",
  "3": "已入款",
  "4": "已取消",
};
const betStatus: { [key: string]: string } = {
  "-1": "已取消",
  "0": "未结算",
  "1": "已结算",
};
const rechargemode: { [key: string]: string } = {
  1: "转账汇款",
  2: "在线支付",
  3: "后台入款",
};

const selectOptionsAll: TranType[] = [{ dictValue: "0", dictName: "全部", dictLabel: "全部" }];

export function userRecord() {
  const betList = computed<ReportItem[]>(() => store.betList);
  const betDetail = computed<BetDetail | null>(() => store.betDetail);
  const billList = computed<TranItem[]>(() => store.billList);
  const transList = computed<QuotaItem[]>(() => store.transList);
  const typeList = computed<TranType[]>(() => store.typeList);
  const date = computed<options[]>(() => store.date);
  const withdrawList = computed<WithdrawData | null>(() => store.withdrawList);
  const rechargerList = computed<RechargerData | null>(() => store.rechargerList);
  // 获取账变记录
  const getBillFlow = async () => {
    const params = {
      dateType: store.day,
      tranType: store.recordType,
      size: store.pagination.pageSize,
      current: store.pagination.current,
    };
    store.loading = true;
    const { data, result, message } = await Api.TransService.billFlow(params);
    store.loading = false;
    if (!result) {
      toast.error(message);
      return;
    }
    store.data = data;
    store.billList =
      store.pagination.current === 1 ? data.records : store.billList.concat(data.records || []);
    store.pagination.total = data.total;
    store.pagination.pages = data.pages;
  };
  const route = useRoute();
  watchEffect(() => {
    if (route.path) {
      store.day = "0";
      store.beginTime = dayjs().startOf("D").valueOf();
      store.endTime = dayjs().endOf("D").valueOf();
    }
  });
  // 获取账变类型，交易记录=>下拉菜单筛选
  const getTransType = async () => {
    const { data, result, message } = await Api.TransService.tranTypes();
    if (!result) {
      toast.error(message);
      store.typeList = selectOptionsAll;
      return;
    }
    store.typeList = selectOptionsAll.concat(data);
  };

  // 获取投注记录
  const betReport = async (beginTime: number, endTime: number) => {
    const time = {
      beginTime,
      endTime,
    };
    store.loading = true;
    const { data, result, message } = await Api.RecordService.betReport(time);
    store.loading = false;
    if (!result) {
      toast.error(message);
      return;
    }
    const { betAmount, betCount, validAmount, winAmount, records } = data;
    store.betList = records;
    store.betDetail = {
      betAmount,
      betCount,
      validAmount,
      winAmount,
    };
  };

  // 获取取款记录
  const getwithdrawList = async (day: string) => {
    const params = {
      dateType: day,
      status: 0,
    };
    const { data, result, message } = await Api.TransService.withdrawList(params);
    if (result) {
      store.withdrawList = data;
    } else {
      toast.error(message);
    }
  };
  // 获取存款记录
  const getrechargerList = async (day: string) => {
    const params = {
      dateType: day,
      status: 0,
    };
    const { data, result, message } = await Api.TransService.rechargerList(params);
    if (result) {
      store.rechargerList = data;
    } else {
      toast.error(message);
    }
  };
  // 获取额度转化记录
  const getquotaList = async () => {
    const params = {
      dateType: "6",
      status: 0,
      size: 200,
      current: 1,
    };
    const { data, result, message } = await Api.TransService.quotaList(params);
    if (result) {
      store.transList = data.records;
    } else {
      toast.error(message);
    }
  };
  return {
    store,
    withdrawStatus,
    getBillFlow,
    betReport,
    getwithdrawList,
    getrechargerList,
    getTransType,
    getquotaList,
    betList,
    date,
    billList,
    transList,
    transferType,
    typeList,
    withdrawList,
    rechargerList,
    rechargeStatus,
    transStatus,
    betStatus,
    rechargemode,
    betDetail,
    statusDot,
  };
}

/**
 * Bet detail for H5 or Pc
 */

// PC表配置
export const PC_TABLE_COLUMNS: any = [
  {
    title: "注单号",
    dataIndex: "billNo",
    key: "billNo",
    align: "center",
    width: 150,
  },
  {
    title: "游戏名称",
    dataIndex: "gameName",
    key: "gameName",
    align: "center",
    width: 120,
  },
  {
    title: "投注额",
    dataIndex: "betAmount",
    key: "betAmount",
    align: "center",
    width: 120,
  },
  {
    title: "有效投注额",
    dataIndex: "validAmount",
    key: "validAmount",
    align: "center",
  },
  {
    title: "输赢",
    dataIndex: "winAmount",
    key: "winAmount",
    align: "center",
    width: 120,
  },
  {
    title: "注单状态",
    dataIndex: "settle",
    customRender: ({ record }: any) => betStatus[record.settle],
    align: "center",
    width: 90,
  },
  {
    title: "投注时间",
    dataIndex: "",
    customRender: ({ record }: any) => toTime(record.betTime),
    align: "center",
    width: 200,
  },
];

export function useBetDetail() {
  const route = useRoute();
  const { isPc } = useConfig();
  // 数据
  const state = reactive({
    data: [] as RecordItem[],
    pagination: {
      total: 0,
      current: 1,
      pageSize: 20,
      pages: 0,
    },
    loading: false,
  });
  // 链接参数
  const gameKindRef: ComputedRef<any> = computed(() => {
    return (
      route.query || {
        gameKind: "",
        gameName: "",
      }
    );
  });

  // 获取投注详情
  const betRecordDetail = async () => {
    const day = route.query.day as string;
    const param: any = dateOptions.find(item => item.value === day);
    const params = {
      current: state.pagination.current,
      size: state.pagination.pageSize,
      gameKind: gameKindRef.value?.gameKind || "",
      status: "",
      beginTime: param.startTime,
      endTime: param.endTime,
    };
    state.loading = true;
    const { data, result, message } = await Api.RecordService.betRecord(params);
    state.loading = false;
    if (!result) {
      toast.error(message);
    } else {
      const records = data.page.records;
      console.log(isPc.value);
      if (isPc.value) {
        state.data = records;
      } else {
        state.data = state.pagination.current === 1 ? records : state.data.concat(records || []);
      }
      state.pagination.total = data.page.total;
      state.pagination.pages = data.page.pages;
    }
  };
  return { state, gameKindRef, betRecordDetail };
}

// 获取当天前十条所有类别的投注详情
export function useBetTopTen() {
  const state = reactive({
    list: [] as RecordItem[],
  });
  const getBetDetail = async () => {
    const params = {
      current: 1,
      size: 10,
      beginTime: new Date(new Date().toLocaleDateString()).getTime(), // 今天00:00:00
      endTime: new Date(new Date().toLocaleDateString()).getTime() + (24 * 60 * 60 * 1000 - 1), // 今天23:59:59
    };
    const { data, result, message } = await Api.RecordService.betRecord(params);
    if (!result) {
      toast.error(message);
    } else {
      state.list = data.page.records;
    }
  };
  return { state, getBetDetail };
}

/**
 * 1、APP初始化环境，设置token
 * 2、检查token是否存在，
 * 3、获取登录token、设置token
 * 4、登出 && 清除token
 */
import { computed, reactive } from "vue";
import { tryOnScopeDispose } from "@vueuse/core";
import { STORE_TOKEN, isHybridApp, getBridgeToken, STORE_GLOBEL_CONFIGS } from "../common";
import { Api } from "../services";
import { useToast } from "./useToast.hook";
import { useEvent } from "../config";
import { useSetWallet } from "./useWallet.hook";
import { useSetUser } from "./useUser.hook";

export interface AuthStore {
  token: string | null;
  userId: number;
  user: any | null;
}

// 不要直接操作该store 以免鉴权信息丢失
const store = reactive<AuthStore>({
  token: localStorage[STORE_TOKEN],
  userId: -1,
  user: null,
});

(async function () {
  if (isHybridApp()) {
    const loca = localStorage[STORE_TOKEN];
    store.token = loca || "";
    const token = await getBridgeToken();
    store.token = token;
    localStorage[STORE_TOKEN] = token;
    console.log(token, "异步获取token");
  }
  console.log("APP初始化环境", isHybridApp());
})();

// 检查token
export async function checkLogin() {
  if (localStorage[STORE_TOKEN]) return true;
  return false;
}
const { tokenEvent } = useEvent();
export function useAuth() {
  const isLogin = computed<boolean>(() => !!store.token);
  tryOnScopeDispose(async () => {
    if (isHybridApp()) {
      const data = await getBridgeToken();
      localStorage[STORE_TOKEN] = data;
      store.token = data;
      return;
    }
    store.token = localStorage[STORE_TOKEN];
  });
  // 此处暴露操作方法 进行数据操作
  // 回调函数
  const getToken = async () => {
    if (isHybridApp()) return (await getBridgeToken()) || localStorage[STORE_TOKEN];
    return localStorage[STORE_TOKEN] || store.token;
  };
  const setToken = (token: string): void => {
    store.token = token || null;
    localStorage[STORE_TOKEN] = token;
  };
  const logout = async (cb?: () => void) => {
    const { toast } = useToast();
    const wallet = useSetWallet().store;
    const user = useSetUser().store;
    try {
      const { result, message } = await Api.AuthService.logout();
      if (!result) {
        toast.error(message);
        return;
      }
      toast.success("退出成功");
      localStorage[STORE_GLOBEL_CONFIGS] = {};
      store.token = localStorage[STORE_TOKEN] = "";
      wallet.lotteryData.balance = 0;
      wallet.lotteryData.freeze = 0;
      wallet.lotteryData.gameTotal = 0;
      wallet.lotteryData.gameBalance = [];
      user.user = null;
      tokenEvent.emit(false);
      cb?.();
    } catch (e) {
      console.error(e);
    }
  };
  return {
    isLogin,
    setToken,
    getToken,
    logout,
  };
}

import {
  CommonService,
  ConfigService,
  AuthService,
  GameService,
  RechargeService,
  UserInfoService,
  BankService,
  merge,
  MessageService,
  RecordService,
  ActivityService,
  TransService,
  WithdrawService,
  DownloadService,
  BannerService,
  FeedbackService,
  LiveService,
  LoanService,
  YuebaoService,
} from "@kgsport-cms/service";
import { requesterFn } from "./requester.service";

type ServiceType = {
  ConfigService: ConfigService;
  CommonService: CommonService;
  AuthService: AuthService;
  GameService: GameService;
  RechargeService: RechargeService;
  UserService: UserInfoService;
  BankService: BankService;
  MessageService: MessageService;
  RecordService: RecordService;
  ActivityService: ActivityService;
  TransService: TransService;
  WithdrawService: WithdrawService;
  DownloadService: DownloadService;
  BannerService: BannerService;
  FeedbackService: FeedbackService;
  LiveService: LiveService;
  LoanService: LoanService;
  YuebaoService: YuebaoService;
};
const Services = {
  ConfigService,
  CommonService,
  AuthService,
  GameService,
  RechargeService,
  BankService,
  UserService: UserInfoService,
  MessageService,
  RecordService,
  ActivityService,
  TransService,
  WithdrawService,
  DownloadService,
  BannerService,
  FeedbackService,
  LiveService,
  LoanService,
  YuebaoService,
};

export const Api = merge<ServiceType>(Services, {
  requesterFn,
  timeout: 10 * 1000,
});

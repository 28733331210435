import dsbridge from "dsbridge";
import { useUrlSearchParams } from "@vueuse/core";
import { STORE_DOWNLOAD } from "../enums";
type BridgeParams = Record<string, any> | string | number | null | Array<any>;
const params = useUrlSearchParams<Record<string, any>>("history");
// app 套壳影藏h5 下载提示
const isHideDownlod =
  localStorage.getItem(STORE_DOWNLOAD) === "1" || Object.keys(params).includes("downlodTip");
export function isHybridApp() {
  if (isHideDownlod) return false;
  if (window._dsbridge || window._dswk) return true;
  return false;
}
function DelayPromise(ms: number) {
  return new Promise(function (resolve, reject) {
    setTimeout(() => {
      reject(new Error("request timeout"));
    }, ms);
  });
}
const ms = 2000;
export function proxyBridge<T = any>(
  name: string,
  params?: BridgeParams,
  callback?: (data: T) => void
): Promise<T> {
  const timeout = DelayPromise(ms);
  const promise = new Promise((resolve, reject) => {
    console.log("bridge:调用名称2", name, params);
    const data = dsbridge.call(name, params, function (res) {
      if (typeof res === "string") {
        try {
          res = JSON.parse(res);
          // eslint-disable-next-line no-empty
        } catch (e) {
          // reject();
        }
      }
      resolve(res);
      callback?.(res);
      console.log("bridge:调用结果", name, res);
    });
  });
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return Promise.race<T>([promise, timeout]);
}

// 原生分享
export async function openBridgeShare(params: any) {
  return await proxyBridge("openShare", params);
}

// 关闭页面
export async function closeBridgePage(params: any) {
  return await proxyBridge("closePage", params);
}
// 打开app页面
export async function openBridgeNativePage(params: any) {
  return await proxyBridge("openNativePage", params);
}
// 跳原生登录
export async function openBridgeNativeLoginPage(params: any) {
  return await proxyBridge("jumpToNativeLogin", params);
}

// 获取app信息
export async function getBridgeAppInfo<T = any>(): Promise<T> {
  return await proxyBridge<T>("getAppInfo");
}
// 获取Token
export async function getBridgeToken() {
  return await proxyBridge<string>("getToken");
}

// 获取T用户详情
export async function getBridgeUserInfo<T = any>(): Promise<T> {
  return await proxyBridge<T>("getUserInfo");
}

// 获取T用户详情
export async function openBridgePage<T = any>(data: any): Promise<T> {
  return await proxyBridge<T>("openPage", data);
}

// 获取主题
export async function getBridgeTheme<T = any>(): Promise<T> {
  return await proxyBridge<T>("getCurrentTheme");
}

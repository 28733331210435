import { defineComponent, defineAsyncComponent, PropType } from "vue";
const components = {
  // 我的
  AccountMA: () => import(/* webpackChunkName: "h5.accountma" */ "templates/MobileAgent/AccountMA"),

  // PC代理中心
  Agent: () => import(/* webpackChunkName: "pc.agent" */ "templates/Agent"),
  //代理报表

  AgentReportPA: () => import(/* webpackChunkName: "pc.agent" */ "templates/Agent/AgentReportPA"),
  // 代理中心账变明细
  AccountChangePA: () =>
    import(/* webpackChunkName: "pc.agentaccountchange" */ "templates/Agent/AccountChangePA"),

  // 代理中心佣金报表頁面
  CommissionReportPA: () =>
    import(/* webpackChunkName: "pc.agentcommissionreport" */ "templates/Agent/CommissionReportPA"),

  // 代理中心联系我们
  AgentContact: () => import(/* webpackChunkName: "pc.agentcontact" */ "templates/Agent/ContactPA"),

  // // 代理中心联系我们内容
  AgentContentInner: () =>
    import(/* webpackChunkName: "pc.agentcontentinner" */ "templates/Agent/ContactPA/ContentInner"),
  //
  // // 代理中心创建下级
  AgentCreateSub: () =>
    import(/* webpackChunkName: "pc.agentcreatesub" */ "templates/Agent/MemberManagerPA/CreateSub"),

  // // 代理中心 數據展示
  AgentData: () =>
    import(/* webpackChunkName: "pc.agentdata" */ "templates/Agent/DownloadPA/AgentData"),

  // 落地页
  DownloadPA: () => import(/* webpackChunkName: "pc.agentdownload" */ "templates/Agent/DownloadPA"),
  // // 代理中心红利记录
  // AgentBonusPage: () =>
  //   import(/* webpackChunkName: "pc.agentbonuspage" */ "templates/Agent/MemberManager/BonusPage"),
  // 代理中心会员详情
  MemberDetail: () =>
    import(/* webpackChunkName: "pc.agentmenberdetail" */ "templates/Agent/MemberManagerPA/detail"),

  // 代理中心财务报表頁面
  FinancialStatementPA: () =>
    import(
      /* webpackChunkName: "pc.agentfinancialstatement" */ "templates/Agent/FinancialStatementPA"
    ),

  // 代理中心入口頁面
  HomePA: () => import(/* webpackChunkName: "pc.agenthome" */ "templates/Agent/HomePA"),

  // PC代理中心-个人中心
  InvitationCodePA: () =>
    import(
      /* webpackChunkName: "pc.agentinvitationcodemanage" */ "templates/Agent/InvitationCodePA"
    ),

  // 代理中心登录
  AgentLogin: () =>
    import(/* webpackChunkName: "pc.agentlogin" */ "templates/Agent/DownloadPA/AgentLogin"),

  // 代理中心会员管理頁面
  MemberPA: () =>
    import(/* webpackChunkName: "pc.agentmenber" */ "templates/Agent/MemberManagerPA"),

  // PC代理中心-溢出申请
  OverflowApplyPA: () =>
    import(/* webpackChunkName: "pc.agentoverflowapply" */ "templates/Agent/OverflowApplyPA"),

  // 代理中心PC Header
  HeaderPA: () => import(/* webpackChunkName: "pc.agentpcheader" */ "templates/Agent/HeaderPA"),

  // PC代理中心-下级排行榜
  RankingListPA: () =>
    import(/* webpackChunkName: "pc.agentrankinglist" */ "templates/Agent/RankingListPA"),

  // 代理中心 返佣宣傳
  AgentReturn: () =>
    import(/* webpackChunkName: "pc.agentreturn" */ "templates/Agent/DownloadPA/AgentReturn"),

  // 代理侧边导航
  SidePA: () => import(/* webpackChunkName: "pc.agentside" */ "templates/Agent/SidePA"),

  // 代理中心推广链接
  SpreadLinkPA: () =>
    import(/* webpackChunkName: "pc.agentspreadlink" */ "templates/Agent/SpreadLinkPA"),

  // PC代理中心-下级排行榜
  SubFinancePA: () =>
    import(/* webpackChunkName: "pc.agentsubordinatefinance" */ "templates/Agent/SubFinancePA"),

  // 底部导航
  TabBarMA: () => import(/* webpackChunkName: "h5.tabBarma" */ "templates/MobileAgent/TabBarMA"),

  // 代理头部tabs
  AgentTabsMA: () =>
    import(/* webpackChunkName: "h5.agenttabsma" */ "templates/MobileAgent/AgentTabsMA"),

  // PC代理中心-个人中心
  UserCenterPA: () =>
    import(/* webpackChunkName: "pc.agentusercenter" */ "templates/Agent/UserCenterPA"),

  // 代理中心 1 TO 1
  AgentVip: () =>
    import(/* webpackChunkName: "pc.agentvip" */ "templates/Agent/DownloadPA/AgentVip"),

  // 佣金
  CommissionMA: () =>
    import(/* webpackChunkName: "h5.commissionma" */ "templates/MobileAgent/CommissionMA"),

  // 代理游戏记录
  GameRecordPA: () =>
    import(/* webpackChunkName: "pc.gamerecord" */ "templates/Agent/GameRecordPA"),

  // H5代理-游戏记录
  GameRecordMA: () =>
    import(/* webpackChunkName: "h5.gamerecordma" */ "templates/MobileAgent/HomeMA/GameRecordMA"),

  // H5代理-邀请码管理
  InvitaCodeMA: () =>
    import(/* webpackChunkName: "h5.invitaCodema" */ "templates/MobileAgent/InvitaCodeMA"),

  // 成员
  MemberMA: () => import(/* webpackChunkName: "h5.memberma" */ "templates/MobileAgent/MemberMA"),

  // H5代理-会员红利
  MemberBonusMA: () =>
    import(/* webpackChunkName: "h5.memberbonusma" */ "templates/MobileAgent/HomeMA/MemberBonusMA"),

  // H5代理中心
  MobileAgent: () => import(/* webpackChunkName: "h5.mobileagent" */ "templates/MobileAgent"),

  // H5代理-返水详情
  BackWaterDetailsMA: () =>
    import(
      /* webpackChunkName: "h5.backWaterDetailsma" */ "templates/MobileAgent/HomeMA/BackWaterDetailsMA"
    ),

  // H5代理-红利详情
  BonusDetailsMA: () =>
    import(
      /* webpackChunkName: "h5.bonusDetailsma" */ "templates/MobileAgent/HomeMA/BonusDetailsMA"
    ),

  // H5代理-更多概览
  GeneralViewMA: () =>
    import(/* webpackChunkName: "h5.generalViewma" */ "templates/MobileAgent/HomeMA/GeneralViewMA"),

  // H5代理-代理登录
  LoginMA: () => import(/* webpackChunkName: "h5.loginma" */ "templates/MobileAgent/LoginMA"),

  // H5代理-净输赢详情
  NetWinDetailsMA: () =>
    import(
      /* webpackChunkName: "h5.netWinDetailsma" */ "templates/MobileAgent/HomeMA/NetWinDetailsMA"
    ),

  // H5代理-溢出申请
  OverflowApplyMA: () =>
    import(
      /* webpackChunkName: "h5.overflowApplyma" */ "templates/MobileAgent/HomeMA/OverflowApplyMA"
    ),

  // H5代理-总输赢详情
  TotalWinDetailsMA: () =>
    import(
      /* webpackChunkName: "h5.totalwindetailsma" */ "templates/MobileAgent/HomeMA/TotalWinDetailsMA"
    ),
  // H5代理-活跃人数详情
  ActiveNumDetailsMA: () =>
    import(
      /* webpackChunkName: "h5.activenumdetailsma" */ "templates/MobileAgent/HomeMA/ActiveNumDetailsMA"
    ),
  // 绑定虚拟账户
  MobileBindUsdtTpl: () =>
    import(
      /* webpackChunkName: "h5.mobilebindusdttpl" */ "templates/ScenceYabo/MobileBank/MobileBindUsdt"
    ),

  // 个人资料
  PersonalInfoMA: () =>
    import(
      /* webpackChunkName: "h5.personalinfoma" */ "templates/MobileAgent/AccountMA/PersonalInfoMA"
    ),

  // 在推广页展示
  PromotionMA: () =>
    import(/* webpackChunkName: "h5.promotionma" */ "templates/MobileAgent/AccountMA/PromotionMA"),

  // 代理中心-充值订单
  RechargeOrdersPA: () =>
    import(/* webpackChunkName: "pc.rechargeorders" */ "templates/Agent/RechargeOrdersPA"),

  // 代理中心-提现订单
  WithdrawOrdersPA: () =>
    import(/* webpackChunkName: "pc.withdraworders" */ "templates/Agent/WithdrawOrdersPA"),

  // 安全中心
  SafeCenterMA: () =>
    import(
      /* webpackChunkName: "h5.safeCenterma" */ "templates/MobileAgent/AccountMA/SafeCenterMA"
    ),

  // 设置
  SettingMA: () =>
    import(/* webpackChunkName: "h5.settingma" */ "templates/MobileAgent/AccountMA/SettingMA"),

  // H5代理-下级成员详情
  MemberDetailMA: () =>
    import(
      /* webpackChunkName: "h5.lowerMemberDetailma" */ "templates/MobileAgent/MemberMA/MemberDetailMA"
    ),
  // 关于我们
  MobileAboutUsTpl: () =>
    import(/* webpackChunkName: "h5.mobileaboutustpl" */ "templates/ScenceYabo/MobileAboutUs"),
  // 通用页脚组件
  Footer: () => import(/* webpackChunkName: "pc.footer" */ "blocks/Footer/src"),
};
export const AsynAgentComp = defineComponent({
  props: {
    name: {
      type: String as PropType<string>,
      default: "",
    },
  },
  setup({ name }) {
    const hasComponent = components[name as keyof typeof components];
    const ComponentNode = defineAsyncComponent({
      loader: async () => {
        //@ts-ignore
        return components[name]?.();
      },
    });
    //@ts-ignore
    return () => (hasComponent ? <ComponentNode /> : <div>组件不存在</div>);
  },
});

import { reactive, computed } from "vue";

interface BaseConfig {
  api: string; //代理api域名
  sitename: string; //代理租户站点名称 document.title 默认取值
  name: string; //租户唯一id
  script?: string; //动态脚本
  scenes?: string; // 预留字段
  h5Scenes?: string;
}

const store = reactive(<BaseConfig>{
  api: window?.CONFIG?.api || "",
  name: window?.CONFIG?.name || "",
  sitename: window?.CONFIG?.sitename || "代理中心",
});

export function useAgConfig() {
  const api = computed<string>(() => store.api);
  const docTitle = computed<string>(() => store.sitename);
  const tenantCode = computed<string>(() => store.name);

  return {
    api,
    docTitle,
    tenantCode,
  };
}

import { useEventBus } from "@vueuse/core";
// token 失效触发
const tokenEvent = useEventBus<boolean>("token");
// 登录触发
const loginEvent = useEventBus<boolean>("login");
// 事件中心 定义各种事件分发
export function useEvent() {
  return {
    tokenEvent,
    loginEvent,
    onToken: tokenEvent.on,
    onLogin: loginEvent.on,
  };
}

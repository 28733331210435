import {
  merge,
  AgentAuthService,
  AgentHomeService,
  LlevelService,
  AFinanceService,
  AspreadService,
} from "@kgsport-cms/service";
import { requesterAgent as requesterFn } from "./v2.requester.service";
type ServiceType = {
  AgentAuthService: AgentAuthService;
  AgentHomeService: AgentHomeService;
  LlevelService: LlevelService;
  AFinanceService: AFinanceService;
  AspreadService: AspreadService;
};
const Services = {
  AgentAuthService,
  AgentHomeService,
  LlevelService,
  AFinanceService,
  AspreadService,
};
export const ApiAgent = merge<ServiceType>(Services, {
  requesterFn,
  timeout: 10 * 1000,
});

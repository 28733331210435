const NAV = window.navigator;
const UA = NAV.userAgent.toLowerCase();
/*
 判断是否是 ssr环境
 */
export const isServer = typeof window == "undefined";

const wind: any = isServer ? null : window;
export const checkTime = () => {
  const str = new Date("2022-01-01 00:00:00");
  return str.toString().includes("Invalid Date");
};
export const isIpad: boolean = /ipad/i.test(UA) && !wind.MSStream;
export const isIphone: boolean = /iphone/i.test(UA) && !wind.MSStream;
export const isIpod: boolean = /ipod/i.test(UA) && !wind.MSStream;
export const isIos: boolean = /ipad|iphone|ipod/i.test(UA) && !wind.MSStream;
export const isAndroid: boolean = /android/i.test(UA);
export const isIe: boolean = /(trident|microsoft)/i.test(NAV ? NAV.appName : "");
export const isMobile: boolean = isIphone || isIos || isAndroid;
export const isChrome: boolean = /chrome/i.test(UA);
export const isFirefox: boolean = /firefox/i.test(UA);
export const isOpera: boolean = /opera/i.test(UA);
export const isSafari: boolean = /safari/i.test(UA) && checkTime();
export const isEdge: boolean = /edge/i.test(UA);
export const isWechat: boolean = /micromessenger/i.test(UA);
export const isQqbrowser: boolean = /qqbrowser/i.test(UA);
export const isUcbrowser: boolean = /ucbrowser/i.test(UA);
// 判断video标签在5大主流浏览器能否使用 国内浏览器兼容大
export const isVideo = isChrome || isFirefox || isOpera || isSafari || isEdge;
//判断浏览器是否支持css var变量
export const isCssVar = window.CSS && window.CSS.supports && window.CSS.supports("--a", "0");

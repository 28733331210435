import axios from "axios";
import qs from "qs";
import { useAAuth, useToast } from "core";
import { makeRandomId, getUUID, isMobile, PROJECT_VERSION } from "../common";
import { getLang, useAgConfig } from "core";
import { useEvent } from "../config";
interface Sign {
  [key: string]: string | number;
}

const { getToken, setToken } = useAAuth();
const { tokenEvent } = useEvent();
const { toast } = useToast();
const requestMap: string[] = [];
const { api } = useAgConfig();
export function requesterAgent() {
  const request = axios.create({
    baseURL: api.value || process.env.CMS_AGENT_API,
    headers: {
      "content-type": "application/x-www-form-urlencoded",
    },
    timeout: 10 * 1000,
  });
  request.interceptors.request.use(
    (config: any) => {
      // 上传文件接口单独处理
      if (config.url === "/api/file/upload") {
        config.headers["content-type"] = "multipart/form-data";
        config.headers = Object.assign(config.headers, creatHeaders());
        return config;
      }
      const requestParams = `${config.url}${JSON.stringify(config.params)}`;
      if (requestMap.includes(requestParams)) return;
      requestMap.push(requestParams);
      if (config?.method && ["post", "put"].includes(config?.method)) {
        config.data = qs.stringify(config.data, {
          arrayFormat: "indices",
          allowDots: true,
        });
      }
      config.headers = Object.assign(config.headers, creatHeaders());
      return config;
    },
    () => {
      return Promise.reject({ code: 1, status: 200, data: {}, result: false });
    }
  );
  request.interceptors.response.use(
    response => {
      const requestParams = `${response.config.url}${JSON.stringify(response.config.params)}`;
      requestMap.splice(requestMap.indexOf(requestParams), 1);
      const { data, status } = response;
      const { code } = data || {};
      if ([401, 403, 405].includes(code)) {
        setToken("");
        tokenEvent.emit(true);
        //toast.error(data.message);
        return Promise.resolve(Object.assign(data, { result: false }));
      }
      if (typeof data === "string" && data.length > 10 && status === 200) {
        return Promise.resolve(Object.assign({ data }, { result: true, status }));
      } else if (code !== 0) {
        toast.error(data.message);
      }
      return Promise.resolve(Object.assign(data, { result: code === 0, status }));
    },
    error => {
      const { response, message } = error;
      const requestParams =
        response && `${response?.config.url}${JSON.stringify(response?.config?.params)}`;
      requestMap.splice(requestMap.indexOf(requestParams), 1);
      const { status, data } = response || {};
      if (message.includes("timeout")) {
        return Promise.resolve(Object.assign(data ? data : {}, { result: false }));
      }
      if ([400, 401, 403, 405].includes(status)) {
        setToken("");
        tokenEvent.emit(true);
        return Promise.resolve(Object.assign(data, { result: false }));
      }
      if (status >= 500 || status === 400) {
        toast.error(data.message);
        return Promise.resolve({ ...response, result: false });
      }
    }
  );
  return request;
}

function creatHeaders() {
  const timestamp = Date.now();
  const sign = makeRandomId(-16);
  const client_type = isMobile ? "h5" : "web";
  const version = PROJECT_VERSION;
  const nonce = makeRandomId(-8);
  const device_id = getUUID();
  const lang = getLang();
  const signParams: Sign = {
    timestamp,
    sign,
    version,
    client_type,
    nonce,
    device_id,
    lang,
  };
  const token = getToken();
  if (token) {
    signParams.Authorization = `Bearer ${token}`;
  }
  return signParams;
}

import { isNumber } from "lodash-es";
// 检测toLocaleString 是否支持
export const isSupportsLocales = (() => {
  try {
    new Date().toLocaleString("i");
    return true;
  } catch (e) {
    return false;
  }
  return false;
})();

/*
 * 参数说明：
 * s：要格式化的数字
 * n：保留几位小数
 * */
export function moneyFormat(s: any, n: number) {
  const f = s < 0 ? "-" : "";
  n = n > 0 && n <= 20 ? n : 2;
  s = parseFloat((Math.abs(s) + "").replace(/[^\d.-]/g, "")).toFixed(n) + "";
  const l = s.split(".")[0].split("").reverse();
  const r = s.split(".")[1];
  let t = "";
  for (let i = 0; i < l.length; i++) {
    t += l[i] + ((i + 1) % 3 == 0 && i + 1 != l.length ? "," : "");
  }
  return f + t.split("").reverse().join("") + "." + r;
}

/*
 * 参数说明：
 * num：要格式化的数字
 * */
export function currency(num: number): string {
  if (isSupportsLocales) {
    return num.toLocaleString();
  }
  return moneyFormat(num, 2);
}

/*
 * 格式化电话号码
 * 参数说明：
 * num：要格式化的电话号码
 * */
export function phoneNumber(num: string): string {
  if (num) {
    return num.substring(0, 3) + "*****" + num.substring(8, 11);
  }
  return "";
}
/*
 * 是否为数字类型
 * 参数说明：
 * value:any
 * */
export function isRealNumber(value: any) {
  return typeof value === "number" && !isNaN(value);
}

import { defineComponent, reactive, PropType } from "vue";
import { useRouter } from "vue-router";
import footer from "./footer.module.less";
import { listTop, sponsor } from "./footerData";
import { useStorage } from "@vueuse/core";
import { useConfigContext } from "core";
export default defineComponent({
  props: {
    isFootLink: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
  },
  setup({ isFootLink }) {
    const { sitename } = useConfigContext();
    const data = reactive({
      heaper: [
        { name: "新手帮助", key: "openaccount" },
        { name: "竞猜责任", key: "duty" },
        { name: "隐私保护", key: "secret" },
        { name: "规则条款", key: "rules" },
        { name: "联系我们", key: "ContactUs" },
        { name: "代理加盟", key: "/JointVenture" },
        { name: "APP下载", key: "/download" },
      ],
    });
    const router = useRouter();
    const storageSrc = useStorage("iframeSrc", {
      selectedKeys: "",
    });
    const clickFooter = (item: { name: string; key: string }) => {
      storageSrc.value = { selectedKeys: item.key };
      if (item.key && ["openaccount", "duty", "secret", "rules", "ContactUs"].includes(item.key)) {
        router.push("/help?key=" + item.key);
      } else {
        router.push(item.key);
      }
    };
    return () => (
      <div class={footer.footer}>
        <div class={[footer.footerTop]}>
          {listTop.map(item => (
            <div class={[footer.footerTopIcon]}>
              <div>
                <img src={item.iconH} alt="" />
              </div>
              <div>
                <img src={item.icon} alt="" />
              </div>
            </div>
          ))}
        </div>
        <div class={footer.footerExplanat}>
          <p>
            集团是取得由英国政府博彩委员会(GamblingCommission)所颁发的离岸博彩许可证，并受其监管。
          </p>
          <p>在英国注册的合法博彩公司。进行注册并娱乐前，请确保您年满18周岁！</p>
        </div>
        <div class={footer.footerSponsor}>
          {sponsor.map(item => (
            <div class={footer.footerSponsorItem}>
              <div class={footer.footerSponsorItemIcon}>
                <img src={item.iconH} alt="" />
                <img src={item.icon} alt="" />
              </div>
              <p>{item.title}</p>
            </div>
          ))}
        </div>
        <div class={footer.footerLink}>
          {isFootLink
            ? data.heaper.map(item => (
                <div onClick={() => clickFooter(item)} class={footer.footerLinkItem}>
                  {item.name}
                </div>
              ))
            : null}
        </div>
        <div class={footer.footerCopyright}> 版权所有 ©2018 - 2022{sitename.value} 保留所有权</div>
      </div>
    );
  },
});

const components = {
 
  // 优质服务组件
  Advantage: ()=>import(/* webpackChunkName: "pc.advantage" */ 'blocks/Advantage/src'),
 
  // 服务优势-天博
  AdvantagesTB: ()=>import(/* webpackChunkName: "pc.advantagestb" */ 'blocks/Advantages/src'),
 
  // 泛亚电竞
  aviaTpl: ()=>import(/* webpackChunkName: "pc.aviatpl" */ 'blocks/avia/src'),
 
  // Banner组件
  Banner: ()=>import(/* webpackChunkName: "pc.banner" */ 'blocks/Banner/src'),
 
  // 数据空组件
  DataEmpty: ()=>import(/* webpackChunkName: "public.dataempty" */ 'blocks/Empty/src'),
 
  // 下载组件
  Download: ()=>import(/* webpackChunkName: "pc.download" */ 'blocks/Download/src'),
 
  // 登录注册封面
  EntrySponsor: ()=>import(/* webpackChunkName: "public.entrysponsor" */ 'blocks/EntrySponsor/src'),
 
  // 403 异常显示
  Exception403: ()=>import(/* webpackChunkName: "public.exception403" */ 'blocks/Exception/403/src'),
 
  // 404 异常显示
  Exception404: ()=>import(/* webpackChunkName: "public.exception404" */ 'blocks/Exception/404/src'),
 
  // 浮动工具栏
  FloatBar: ()=>import(/* webpackChunkName: "pc.floatbar" */ 'blocks/FloatBar/src'),
 
  // 首页浮窗
  FloatPopup: ()=>import(/* webpackChunkName: "pc.floatpopup" */ 'blocks/FloatPopup/src'),
 
  // 页脚组件
  Footer: ()=>import(/* webpackChunkName: "pc.footer" */ 'blocks/Footer/src'),
 
  // 热门游戏
  HotGame: ()=>import(/* webpackChunkName: "pc.hotgame" */ 'blocks/HotGame/src'),
 
  // 热门游戏-火狐
  HotGameHh: ()=>import(/* webpackChunkName: "pc.hotgamehh" */ 'blocks/HotGameHh/src'),
 
  // 热门游戏-华体会
  HotGameHth: ()=>import(/* webpackChunkName: "pc.hotgamehth" */ 'blocks/HotGameHth/src'),
 
  // 热门游戏-KB
  HotGameKb: ()=>import(/* webpackChunkName: "pc.hotgamekb" */ 'blocks/HotGameKb/src'),
 
  // 热门游戏-天博
  HotGameTB: ()=>import(/* webpackChunkName: "pc.hotgametb" */ 'blocks/HotGameTB/src'),
 
  // 热门直播
  HotLive: ()=>import(/* webpackChunkName: "pc.hotlive" */ 'blocks/HotLive/src'),
 
  // 落地页
  LandingPage: ()=>import(/* webpackChunkName: "public.landingpage" */ 'blocks/Download/LandingPage/src'),
 
  // 登录
  login: ()=>import(/* webpackChunkName: "public.login" */ 'blocks/login/src'),
 
  // 大转盘组件
  LuckyWheel: ()=>import(/* webpackChunkName: "public.luckywheel" */ 'blocks/LuckyWheel/src'),
 
  // Banner 轮播图
  MobileBanner: ()=>import(/* webpackChunkName: "h5.mobilebanner" */ 'blocks/MobileBanner/src'),
 
  // H5客服
  MobileCustomer: ()=>import(/* webpackChunkName: "h5.mobilecustomer" */ 'blocks/MobileCustomer/src'),
 
  // App下载
  MobileDownload: ()=>import(/* webpackChunkName: "h5.mobiledownload" */ 'blocks/MobileDownload/src'),
 
  // 浮窗H5
  MobileFloatPopup: ()=>import(/* webpackChunkName: "mobile.mobilefloatpopup" */ 'blocks/MobileFloatPopup/src'),
 
  // 游戏列表
  MobileGame: ()=>import(/* webpackChunkName: "h5.mobilegame" */ 'blocks/MobileGame/src'),
 
  // 游戏列表场景一
  MobileGameScence1: ()=>import(/* webpackChunkName: "h5.mobilegamescence1" */ 'blocks/MobileGame/Scenes1/src'),
 
  // 游戏列表场景二
  MobileGameScence2: ()=>import(/* webpackChunkName: "h5.mobilegamescence2" */ 'blocks/MobileGame/Scenes2/src'),
 
  // 游戏列表场景三
  MobileGameScence3: ()=>import(/* webpackChunkName: "h5.mobilegamescence3" */ 'blocks/MobileGame/Scenes3/src'),
 
  // 游戏列表场景四
  MobileGameScence4: ()=>import(/* webpackChunkName: "h5.mobilegamescence4" */ 'blocks/MobileGame/Scenes4/src'),
 
  // H5场景7
  MobileGameScence7: ()=>import(/* webpackChunkName: "h5.mobilegamescence7" */ 'blocks/MobileGame/Scenes7/src'),
 
  // 游戏列表场景Bob
  MobileGameScenceBob: ()=>import(/* webpackChunkName: "h5.mobilegamescencebob" */ 'blocks/MobileGame/ScenesBob/src'),
 
  // 游戏列表KG
  MobileGameScenceKg: ()=>import(/* webpackChunkName: "h5.mobilegamescencekg" */ 'blocks/MobileGame/ScenesKg/src'),
 
  // H5场景6
  MobileGameScenesLottery: ()=>import(/* webpackChunkName: "h5.mobilegamesceneslottery" */ 'blocks/MobileGame/ScenesLottery/src'),
 
  // 热门赛事
  MobileHotgame: ()=>import(/* webpackChunkName: "h5.mobilehotgame" */ 'blocks/MobileHotgame/src'),
 
  // H5新登录
  MobileNewLogin: ()=>import(/* webpackChunkName: "public.mobilenewlogin" */ 'blocks/MobileNewLogin/src'),
 
  // H5新注册组件
  MobileNewRegister: ()=>import(/* webpackChunkName: "public.mobilenewregister" */ 'blocks/MobileNewRegister/src'),
 
  // 广播
  MobileNotice: ()=>import(/* webpackChunkName: "h5.mobilenotice" */ 'blocks/MobileNotice/src'),
 
  // 弹窗
  MobilePopup: ()=>import(/* webpackChunkName: "h5.mobilepopup" */ 'blocks/MobilePopup/src'),
 
  // 底部导航
  MobileTabBar: ()=>import(/* webpackChunkName: "h5.mobiletabbar" */ 'blocks/MobileTabBar/src'),
 
  // 用户卡片
  MobileUserCard: ()=>import(/* webpackChunkName: "h5.mobileusercard" */ 'blocks/MobileUserCard/src'),
 
  // 导航游戏列表-默认场景
  NavGameListScenes1: ()=>import(/* webpackChunkName: "public.navgamelistscenes1" */ 'blocks/NavGameList/Scenes1/src'),
 
  // 导航游戏列表-场景1
  NavGameListScenesKg: ()=>import(/* webpackChunkName: "public.navgamelistsceneskg" */ 'blocks/NavGameList/ScenesKg/src'),
 
  // 新登录
  newLogin: ()=>import(/* webpackChunkName: "pc.newlogin" */ 'blocks/NewLogin/src'),
 
  // 通用注册组件
  newRegister: ()=>import(/* webpackChunkName: "pc.newregister" */ 'blocks/NewRegister/src'),
 
  // 通知组件
  Notice: ()=>import(/* webpackChunkName: "pc.notice" */ 'blocks/Notice/src'),
 
  // 财神到红包雨
  RedEnvelope: ()=>import(/* webpackChunkName: "public.redenvelope" */ 'blocks/RedEnvelope/src'),
 
  // 注册组件
  register: ()=>import(/* webpackChunkName: "public.register" */ 'blocks/register/src'),
 
  // 赞助列表
  Sponsor: ()=>import(/* webpackChunkName: "pc.sponsor" */ 'blocks/Sponsor/SponsorList/src'),
 
  // 赞助列表
  Sponsor188: ()=>import(/* webpackChunkName: "pc.sponsor188" */ 'blocks/Sponsor/Sponsor188/src'),
 
  // 255-赞助详情
  Sponsor255: ()=>import(/* webpackChunkName: "pc.sponsor255" */ 'blocks/Sponsor/Sponsor255/src'),
 
  // 修改密码和手机号
  SponsorDetailsTpl: ()=>import(/* webpackChunkName: "h5.sponsordetailstpl" */ 'blocks/Sponsor/SponsorDetails/src'),
 
  // 浮窗赞助列表
  SponsorFloat: ()=>import(/* webpackChunkName: "pc.sponsorfloat" */ 'blocks/Sponsor/SponsorFloat/src'),
 
  // 赞助详情布局
  SponsorLayout: ()=>import(/* webpackChunkName: "pc.sponsorlayout" */ 'blocks/Sponsor/SponsorLayout/src'),
 
  // 精彩游戏
  WonderfulGame: ()=>import(/* webpackChunkName: "pc.wonderfulgame" */ 'blocks/WonderfulGame/src'),
 
  // 107-奥博-火爆游戏
  AbHotGames: ()=>import(/* webpackChunkName: "pc.abhotgames" */ 'templates/Scene107/components/HotGames'),
 
  // 107-奥博-真人视讯二级界面
  AbLiveVideo: ()=>import(/* webpackChunkName: "pc.ablivevideo" */ 'templates/Scene107/components/LiveVideo'),
 
  // 个人中心组件
  AccountCenter: ()=>import(/* webpackChunkName: "pc.accountcenter" */ 'templates/ScenceYabo/AccountCenter'),
 
  // 个人中心组件255
  AccountCenter255: ()=>import(/* webpackChunkName: "pc.accountcenter255" */ 'templates/Scene255/AccountCenter'),
 
  // 用户中心容器
  AccountContainer: ()=>import(/* webpackChunkName: "pc.accountcontainer" */ 'templates/AccountContainer'),
 
  // 优惠活动列表105
  Activity105: ()=>import(/* webpackChunkName: "pc.activity105" */ 'templates/Scene105/Activity'),
 
  // 优惠活动列表107
  Activity107: ()=>import(/* webpackChunkName: "pc.activity107" */ 'templates/Scene107/Activity'),
 
  // Scence118-活动
  Activity118: ()=>import(/* webpackChunkName: "pc.activity118" */ 'templates/Scene118/Activity'),
 
  // 优惠活动列表123
  Activity123: ()=>import(/* webpackChunkName: "pc.activity123" */ 'templates/Scene123/Activity'),
 
  // 活动详情
  ActivityDetailTpl: ()=>import(/* webpackChunkName: "pc.activitydetailtpl" */ 'templates/ScenceYabo/Activity/ActiveDetails'),
 
  // 优惠活动列表Hk
  ActivityHk: ()=>import(/* webpackChunkName: "pc.activityhk" */ 'templates/SceneHk/Activity'),
 
  // 活动模板
  ActivityTpl: ()=>import(/* webpackChunkName: "pc.activitytpl" */ 'templates/ScenceYabo/Activity/Activity'),
 
  // PC代理中心
  Agent: ()=>import(/* webpackChunkName: "pc.agent" */ 'templates/Agent'),
 
  // 107申请代理
  agent107: ()=>import(/* webpackChunkName: "pc.agent107" */ 'templates/Scene107/Agent'),
 
  // 落地页
  AgentDownload: ()=>import(/* webpackChunkName: "pc.agentdownload" */ 'templates/Agent/DownloadPA'),
 
  // 255场景-银行卡添加
  BankModule: ()=>import(/* webpackChunkName: "pc.bankmodule" */ 'templates/Scene255/BankModule'),
 
  // 银行卡绑定
  BankTpl: ()=>import(/* webpackChunkName: "pc.banktpl" */ 'templates/ScenceYabo/Bank'),
 
  // KG105 首页轮播图
  Banner105: ()=>import(/* webpackChunkName: "pc.banner105" */ 'templates/Scene105/Banner'),
 
  // KG107 首页轮播图
  Banner107: ()=>import(/* webpackChunkName: "pc.banner107" */ 'templates/Scene107/Banner'),
 
  // KC122MGM-首页轮播图
  Banner122: ()=>import(/* webpackChunkName: "pc.banner122" */ 'templates/Scene122/Banner'),
 
  // 气泡canvas背景
  canvasBubbling: ()=>import(/* webpackChunkName: "pc.canvasbubbling" */ 'templates/Scene105/components/canvas'),
 
  // 二级游戏模板
  EgameTpl: ()=>import(/* webpackChunkName: "pc.egametpl" */ 'templates/ScenceYabo/Egame'),
 
  // 意见反馈
  FeedbackTpl: ()=>import(/* webpackChunkName: "pc.feedbacktpl" */ 'templates/ScenceYabo/Feedback'),
 
  // 一级游戏模板
  GameTpl: ()=>import(/* webpackChunkName: "pc.gametpl" */ 'templates/ScenceYabo/Game'),
 
  // 导航组件
  Header: ()=>import(/* webpackChunkName: "pc.header" */ 'templates/ScenceYabo/Header'),
 
  // 帮助中心
  HelpTpl: ()=>import(/* webpackChunkName: "pc.helptpl" */ 'templates/ScenceYabo/Help'),
 
  // 皇冠116-公告组件
  Hg116Notice: ()=>import(/* webpackChunkName: "pc.hg116notice" */ 'templates/SceneHg/components/Notice'),
 
  // 澳冠-真人视讯
  HgLive: ()=>import(/* webpackChunkName: "pc.hglive" */ 'templates/SceneHg/Live'),
 
  // 金宝博-应用程序
  JbbApplication: ()=>import(/* webpackChunkName: "pc.jbbapplication" */ 'templates/Scence188/Application'),
 
  // 金宝博-娱乐场
  JbbCasino: ()=>import(/* webpackChunkName: "pc.jbbcasino" */ 'templates/Scence188/Casino'),
 
  // 金宝博-棋牌
  JbbChess: ()=>import(/* webpackChunkName: "pc.jbbchess" */ 'templates/Scence188/Chess'),
 
  // 金宝博-FloatBar
  JbbFloatBar: ()=>import(/* webpackChunkName: "pc.jbbfloatbar" */ 'templates/Scence188/FloatBar'),
 
  // 金宝博-footer
  JbbFooter: ()=>import(/* webpackChunkName: "pc.jbbfooter" */ 'templates/Scence188/Footer'),
 
  // 188-游戏二级界面
  JbbGame: ()=>import(/* webpackChunkName: "pc.jbbgame" */ 'templates/Scence188/Game'),
 
  // 188-导航组件
  JbbHeader: ()=>import(/* webpackChunkName: "pc.jbbheader" */ 'templates/Scence188/Header'),
 
  // 金宝博-首页
  JbbHome: ()=>import(/* webpackChunkName: "pc.jbbhome" */ 'templates/Scence188/Home'),
 
  // 金宝博-真人荷官
  JbbLive: ()=>import(/* webpackChunkName: "pc.jbblive" */ 'templates/Scence188/Live'),
 
  // 金宝博-彩票
  JbbLottery: ()=>import(/* webpackChunkName: "pc.jbblottery" */ 'templates/Scence188/Lottery'),
 
  // 金宝博-应用程序（h5）
  JbbMobileApp: ()=>import(/* webpackChunkName: "h5.jbbmobileapp" */ 'templates/Scence188/MobileApplication'),
 
  // 金宝博-娱乐场（h5）
  JbbMobileCasino: ()=>import(/* webpackChunkName: "h5.jbbmobilecasino" */ 'templates/Scence188/MobileCasino'),
 
  // 金宝博-棋牌（h5）
  JbbMobileChess: ()=>import(/* webpackChunkName: "h5.jbbmobilechess" */ 'templates/Scence188/MobileChess'),
 
  // 188-游戏二级界面(H5)
  JbbMobileGame: ()=>import(/* webpackChunkName: "h5.jbbmobilegame" */ 'templates/Scence188/MobileGame'),
 
  // 导航组件(h5-金宝博)
  JbbMobileHeader: ()=>import(/* webpackChunkName: "h5.jbbmobileheader" */ 'templates/Scence188/MobileHeader'),
 
  // 金宝博-首页（h5）
  JbbMobileHome: ()=>import(/* webpackChunkName: "h5.jbbmobilehome" */ 'templates/Scence188/MobileHome'),
 
  // 金宝博-真人荷官（h5）
  JbbMobileLive: ()=>import(/* webpackChunkName: "h5.jbbmobilelive" */ 'templates/Scence188/MobileLive'),
 
  // 金宝博-彩票（h5）
  JbbMobileLottery: ()=>import(/* webpackChunkName: "h5.jbbmobilelottery" */ 'templates/Scence188/MobileLottery'),
 
  // 金宝博-优惠（h5）
  JbbMobilePromotions: ()=>import(/* webpackChunkName: "h5.jbbmobilepromotions" */ 'templates/Scence188/MobilePromotions'),
 
  // 金宝博-优惠
  JbbPromotions: ()=>import(/* webpackChunkName: "pc.jbbpromotions" */ 'templates/Scence188/Promotions'),
 
  // 金宝博-优惠
  JbbPromotionsDetail: ()=>import(/* webpackChunkName: "pc.jbbpromotionsdetail" */ 'templates/Scence188/Promotions/detail'),
 
  // 合营
  JointVentureTpl: ()=>import(/* webpackChunkName: "pc.jointventuretpl" */ 'templates/ScenceYabo/JointVenture'),
 
  // 合营
  JointVentureTplAm: ()=>import(/* webpackChunkName: "pc.jointventuretplam" */ 'templates/ScenceAm/JointVenture'),
 
  // 105-公共左右两侧浮窗
  KG105FloatSlider: ()=>import(/* webpackChunkName: "pc.kg105floatslider" */ 'templates/Scene105/FloatSlider'),
 
  // 105-游戏二级界面
  KG105Game: ()=>import(/* webpackChunkName: "pc.kg105game" */ 'templates/Scene105/Game'),
 
  // 105-公共左右两侧浮窗
  KG107FloatSlider: ()=>import(/* webpackChunkName: "pc.kg107floatslider" */ 'templates/Scene107/FloatSlider'),
 
  // 107-游戏二级界面
  KG107Game: ()=>import(/* webpackChunkName: "pc.kg107game" */ 'templates/Scene107/Game'),
 
  // 117-游戏二级界面
  KG117Game: ()=>import(/* webpackChunkName: "pc.kg117game" */ 'templates/ScenceAm/Game'),
 
  // 118-游戏二级界面
  KG118Game: ()=>import(/* webpackChunkName: "pc.kg118game" */ 'templates/Scene118/Game'),
 
  // 122-游戏二级界面
  KG122Game: ()=>import(/* webpackChunkName: "pc.kg122game" */ 'templates/Scene122/Game'),
 
  // 123左右两侧浮窗
  KG123FloatSlider: ()=>import(/* webpackChunkName: "pc.kg123floatslider" */ 'templates/Scene123/FloatSlider'),
 
  // 123-游戏二级界面
  KG123Game: ()=>import(/* webpackChunkName: "pc.kg123game" */ 'templates/Scene123/Game'),
 
  // KG123金沙娱乐场-导航组件
  KG123Header: ()=>import(/* webpackChunkName: "pc.kg123header" */ 'templates/Scene123/Header'),
 
  // 123-金沙娱乐场真人视讯二级界面
  Kg123LiveVideo: ()=>import(/* webpackChunkName: "pc.kg123livevideo" */ 'templates/Scene123/components/LiveVideo'),
 
  // 真人视讯
  liveModelTpl: ()=>import(/* webpackChunkName: "pc.livemodeltpl" */ 'templates/Scene105/components/liveModel'),
 
  // 借款记录
  LoanRecordTpl: ()=>import(/* webpackChunkName: "pc.loanrecordtpl" */ 'templates/ScenceYabo/Loan/record'),
 
  // 借呗
  LoanTpl: ()=>import(/* webpackChunkName: "pc.loantpl" */ 'templates/ScenceYabo/Loan/loan'),
 
  // 大转盘抽奖活动
  LuckWheelLotteryTpl: ()=>import(/* webpackChunkName: "pc.luckwheellotterytpl" */ 'templates/ScenceYabo/Activity/LuckWheelLottery'),
 
  // 255场景-关于我们
  MobileAbout255: ()=>import(/* webpackChunkName: "h5.mobileabout255" */ 'templates/Scene255/MobileAboutKg'),
 
  // 关于我们
  MobileAboutUsTpl: ()=>import(/* webpackChunkName: "h5.mobileaboutustpl" */ 'templates/ScenceYabo/MobileAboutUs'),
 
  // 我的账户
  MobileAccountTpl: ()=>import(/* webpackChunkName: "h5.mobileaccounttpl" */ 'templates/ScenceYabo/MobileAccount/MobileAccount'),
 
  // 个人中心(255)
  MobileAccountTpl255: ()=>import(/* webpackChunkName: "h5.mobileaccounttpl255" */ 'templates/Scene255/MobileAccount'),
 
  // 活动详情模版
  MobileActivityDetail: ()=>import(/* webpackChunkName: "h5.mobileactivitydetail" */ 'templates/ScenceYabo/MobileActivity/MobileActivityDetail'),
 
  // 优惠活动模版
  MobileActivityTpl: ()=>import(/* webpackChunkName: "h5.mobileactivitytpl" */ 'templates/ScenceYabo/MobileActivity/MobileActivity'),
 
  // H5代理中心
  MobileAgent: ()=>import(/* webpackChunkName: "h5.mobileagent" */ 'templates/MobileAgent'),
 
  // 代理登录
  MobileAgentLogin: ()=>import(/* webpackChunkName: "h5.mobileagentlogin" */ 'templates/MobileAgent/LoginMA'),
 
  // 账户管理
  MobileBankTpl: ()=>import(/* webpackChunkName: "h5.mobilebanktpl" */ 'templates/ScenceYabo/MobileBank/MobileBankList'),
 
  // 关于我们
  MobileBettingRulesTpl: ()=>import(/* webpackChunkName: "h5.mobilebettingrulestpl" */ 'templates/ScenceYabo/MobileBettingRules'),
 
  // 绑定银行卡
  MobileBindCardTpl: ()=>import(/* webpackChunkName: "h5.mobilebindcardtpl" */ 'templates/ScenceYabo/MobileBank/MobileBindCard'),
 
  // 绑定虚拟账户
  MobileBindUsdtTpl: ()=>import(/* webpackChunkName: "h5.mobilebindusdttpl" */ 'templates/ScenceYabo/MobileBank/MobileBindUsdt'),
 
  // 联系我们
  MobileContactUsTpl: ()=>import(/* webpackChunkName: "h5.mobilecontactustpl" */ 'templates/ScenceYabo/MobileAccount/MobileContactUs'),
 
  // H5电子游戏二级页
  MobileEgame: ()=>import(/* webpackChunkName: "h5.mobileegame" */ 'templates/ScenceYabo/MobileEgame'),
 
  // 意见反馈
  MobileFeedbackTpl: ()=>import(/* webpackChunkName: "h5.mobilefeedbacktpl" */ 'templates/ScenceYabo/MobileFeedback'),
 
  // 游戏中心
  MobileGameCenter: ()=>import(/* webpackChunkName: "h5.mobilegamecenter" */ 'templates/ScenceYabo/MobileGameCenter'),
 
  // 255场景-游戏规则
  MobileGameRule255: ()=>import(/* webpackChunkName: "h5.mobilegamerule255" */ 'templates/Scene255/MobileGameRule'),
 
  // 盘口教程
  MobileHandicapTpl: ()=>import(/* webpackChunkName: "h5.mobilehandicaptpl" */ 'templates/ScenceYabo/MobileHandicap'),
 
  // 255场景-帮助中心
  MobileHelp255: ()=>import(/* webpackChunkName: "h5.mobilehelp255" */ 'templates/Scene255/MobileHelp'),
 
  // 帮助中心
  MobileHelpTpl: ()=>import(/* webpackChunkName: "h5.mobilehelptpl" */ 'templates/ScenceYabo/MobileAccount/MobileHelp'),
 
  // 加入我们
  MobileJoinusTpl: ()=>import(/* webpackChunkName: "h5.mobilejoinustpl" */ 'templates/ScenceYabo/MobileAccount/MobileJoinus'),
 
  // 借款记录
  MobileLoanRecordTpl: ()=>import(/* webpackChunkName: "h5.mobileloanrecordtpl" */ 'templates/ScenceYabo/MobileAccount/MobileLoan/record'),
 
  // 借呗
  MobileLoanTpl: ()=>import(/* webpackChunkName: "h5.mobileloantpl" */ 'templates/ScenceYabo/MobileAccount/MobileLoan/loan'),
 
  // 活动详情模版
  MobileRedEnvelope: ()=>import(/* webpackChunkName: "h5.mobileredenvelope" */ 'templates/ScenceYabo/MobileActivity/RedEnvelopeRain'),
 
  // 场景255-安全中心
  MobileSecurityCenterTpl: ()=>import(/* webpackChunkName: "h5.mobilesecuritycentertpl" */ 'templates/Scene255/MobileSecurityCenter'),
 
  // 修改密码和手机号
  MobileSetTpl: ()=>import(/* webpackChunkName: "h5.mobilesettpl" */ 'templates/ScenceYabo/MobileSet'),
 
  // 模拟教程
  MobileSimulateTpl: ()=>import(/* webpackChunkName: "h5.mobilesimulatetpl" */ 'templates/ScenceYabo/MobileSimulate'),
 
  // 设置模板H5
  MobileSystem: ()=>import(/* webpackChunkName: "h5.mobilesystem" */ 'templates/ScenceYabo/MobileAccount/MobileSystem'),
 
  // 个人资料
  MobileUserInfoTpl: ()=>import(/* webpackChunkName: "h5.mobileuserinfotpl" */ 'templates/ScenceYabo/MobileAccount/MobileUserInfo'),
 
  // VIP详情
  MobileVipDetailsTpl: ()=>import(/* webpackChunkName: "h5.mobilevipdetailstpl" */ 'templates/ScenceYabo/MobileVip/MobileVipDetails'),
 
  // 255场景-VIP详情
  MobileVipDetailsTpl255: ()=>import(/* webpackChunkName: "h5.mobilevipdetailstpl255" */ 'templates/Scene255/MobileVip/MobileVipDetail'),
 
  // VIP中心
  MobileVipTpl: ()=>import(/* webpackChunkName: "h5.mobileviptpl" */ 'templates/ScenceYabo/MobileVip/MobileVip'),
 
  // 255场景-VIP中心
  MobileVipTpl255: ()=>import(/* webpackChunkName: "h5.mobileviptpl255" */ 'templates/Scene255/MobileVip/MobileVip'),
 
  // 255场景-会员教程
  MobileVipTutorial255: ()=>import(/* webpackChunkName: "h5.mobileviptutorial255" */ 'templates/Scene255/MobileVipTutorial'),
 
  // 255场景-会员教程详情
  MobileVipTutorialDetail255: ()=>import(/* webpackChunkName: "h5.mobileviptutorialdetail255" */ 'templates/Scene255/MobileVipTutorialDetail'),
 
  // 余额宝
  MobileYuebaoTpl: ()=>import(/* webpackChunkName: "h5.mobileyuebaotpl" */ 'templates/ScenceYabo/MobileAccount/MobileYuebao'),
 
  // 255场景-我的成长值
  MyGrowthValue255: ()=>import(/* webpackChunkName: "h5.mygrowthvalue255" */ 'templates/Scene255/MobileVip/MyGrowthValue'),
 
  // 通知组件
  Notice107: ()=>import(/* webpackChunkName: "pc.notice107" */ 'templates/Scene107/Notice'),
 
  // KC122MGM-竖向滚动公告
  Notice122: ()=>import(/* webpackChunkName: "pc.notice122" */ 'templates/Scene122/Banner/components/Notice'),
 
  // 通知组件
  Notice123: ()=>import(/* webpackChunkName: "pc.notice123" */ 'templates/Scene123/Notice'),
 
  // p3-底部组件
  P3Footer: ()=>import(/* webpackChunkName: "pc.p3footer" */ 'templates/SceneP3/Footer'),
 
  // P3Header
  P3Header: ()=>import(/* webpackChunkName: "pc.p3header" */ 'templates/SceneP3/Header'),
 
  // p3-服务
  P3Service: ()=>import(/* webpackChunkName: "pc.p3service" */ 'templates/SceneP3/Service'),
 
  // p3-体育
  P3Sports: ()=>import(/* webpackChunkName: "pc.p3sports" */ 'templates/SceneP3/Sports'),
 
  // 新葡京-公共左右两侧浮窗
  PjSlider: ()=>import(/* webpackChunkName: "pc.pjslider" */ 'templates/ScenePj/Sidebar'),
 
  // 107滚动奖金池
  pollScroll107: ()=>import(/* webpackChunkName: "pc.pollscroll107" */ 'templates/Scene107/PollScroll'),
 
  // 107-奖金池
  prizepoll107: ()=>import(/* webpackChunkName: "pc.prizepoll107" */ 'templates/Scene107/PrizePool'),
 
  // 红包雨活动
  RedEnvelopeRain: ()=>import(/* webpackChunkName: "pc.redenveloperain" */ 'templates/ScenceYabo/Activity/RedEnvelopeRain'),
 
  // 255-安全中心
  safeCenter255: ()=>import(/* webpackChunkName: "pc.safecenter255" */ 'templates/Scene255/SafeCenterArchive'),
 
  // Scence105-首页
  Scence105Home: ()=>import(/* webpackChunkName: "pc.scence105home" */ 'templates/Scene105/Home'),
 
  // 105-真人娱乐
  Scence105Live: ()=>import(/* webpackChunkName: "pc.scence105live" */ 'templates/Scene105/components/Live'),
 
  // 105-彩票
  Scence105Lottery: ()=>import(/* webpackChunkName: "pc.scence105lottery" */ 'templates/Scene105/components/Lottery'),
 
  // Scence107-游戏列表
  Scence107gameList: ()=>import(/* webpackChunkName: "pc.scence107gamelist" */ 'templates/Scene107/GameList'),
 
  // Scence107-首页
  Scence107Home: ()=>import(/* webpackChunkName: "pc.scence107home" */ 'templates/Scene107/Home'),
 
  // Scence118-首页
  Scence118Home: ()=>import(/* webpackChunkName: "pc.scence118home" */ 'templates/Scene118/Home'),
 
  // Scence120-优惠活动
  Scence120Activity: ()=>import(/* webpackChunkName: "pc.scence120activity" */ 'templates/Scene120/Activity'),
 
  // Scence120-banner
  Scence120Banner: ()=>import(/* webpackChunkName: "pc.scence120banner" */ 'templates/Scene120/Banner'),
 
  // Scence120-footer
  Scence120Footer: ()=>import(/* webpackChunkName: "pc.scence120footer" */ 'templates/Scene120/Footer'),
 
  // Scence120-游戏二级界面
  Scence120Game: ()=>import(/* webpackChunkName: "pc.scence120game" */ 'templates/Scene120/Game'),
 
  // Scence120-header
  Scence120Header: ()=>import(/* webpackChunkName: "pc.scence120header" */ 'templates/Scene120/Header'),
 
  // Scence120-首页
  Scence120Home: ()=>import(/* webpackChunkName: "pc.scence120home" */ 'templates/Scene120/Home'),
 
  // Scence120-Notice
  Scence120Notice: ()=>import(/* webpackChunkName: "pc.scence120notice" */ 'templates/Scene120/Notice'),
 
  // 澳门国际-优惠活动二级界面
  ScenceAmActivity: ()=>import(/* webpackChunkName: "pc.scenceamactivity" */ 'templates/ScenceAm/Activity'),
 
  // Am-首页轮播图
  ScenceAmBanner: ()=>import(/* webpackChunkName: "pc.scenceambanner" */ 'templates/ScenceAm/components/Banner'),
 
  // Scence澳门-首页
  ScenceAmHome: ()=>import(/* webpackChunkName: "pc.scenceamhome" */ 'templates/ScenceAm/Home'),
 
  // Am-二级页公告
  ScenceAmImgPublic: ()=>import(/* webpackChunkName: "pc.scenceamimgpublic" */ 'templates/ScenceAm/components/ImgPubilc'),
 
  // Scence澳门-真人二级页
  ScenceAmLive: ()=>import(/* webpackChunkName: "pc.scenceamlive" */ 'templates/ScenceAm/components/Live'),
 
  // Scence复星-banner
  ScenceFxBanner: ()=>import(/* webpackChunkName: "pc.scencefxbanner" */ 'templates/SceneFx/components/Banner'),
 
  // Scence复星-footer
  ScenceFxFooter: ()=>import(/* webpackChunkName: "pc.scencefxfooter" */ 'templates/SceneFx/Footer'),
 
  // Scence复星-首页
  ScenceFxHome: ()=>import(/* webpackChunkName: "pc.scencefxhome" */ 'templates/SceneFx/Home'),
 
  // Scence复星-HotLive
  ScenceFxHotLive: ()=>import(/* webpackChunkName: "pc.scencefxhotlive" */ 'templates/SceneFx/components/HotLive/src'),
 
  // Scence皇冠-首页
  ScenceHgHome: ()=>import(/* webpackChunkName: "pc.scencehghome" */ 'templates/SceneHg/Home'),
 
  // Scence金沙娱乐场-首页
  ScenceKG123Home: ()=>import(/* webpackChunkName: "pc.scencekg123home" */ 'templates/Scene123/Home'),
 
  // 105-电竞
  Scene105ESport: ()=>import(/* webpackChunkName: "pc.scene105esport" */ 'templates/Scene105/components/ESport'),
 
  // 105-捕鱼
  Scene105Fishing: ()=>import(/* webpackChunkName: "pc.scene105fishing" */ 'templates/Scene105/components/Fishing'),
 
  // 105-底部组件
  Scene105Footer: ()=>import(/* webpackChunkName: "pc.scene105footer" */ 'templates/Scene105/Footer'),
 
  // 105-导航组件
  Scene105Header: ()=>import(/* webpackChunkName: "pc.scene105header" */ 'templates/Scene105/Header'),
 
  // 105-通知组件
  Scene105Notice: ()=>import(/* webpackChunkName: "pc.scene105notice" */ 'templates/Scene105/Notice'),
 
  // 105-体育
  Scene105Sport: ()=>import(/* webpackChunkName: "pc.scene105sport" */ 'templates/Scene105/components/Sport'),
 
  // 107-底部组件
  Scene107Footer: ()=>import(/* webpackChunkName: "pc.scene107footer" */ 'templates/Scene107/Footer'),
 
  // 107-Header
  Scene107Header: ()=>import(/* webpackChunkName: "pc.scene107header" */ 'templates/Scene107/Header'),
 
  // 107-我们的服务
  Scene107Service: ()=>import(/* webpackChunkName: "pc.scene107service" */ 'templates/Scene107/Service'),
 
  // 107-体育
  Scene107Sport: ()=>import(/* webpackChunkName: "pc.scene107sport" */ 'templates/Scene107/components/Sport'),
 
  // 118-底部组件
  Scene118Footer: ()=>import(/* webpackChunkName: "pc.scene118footer" */ 'templates/Scene118/Footer'),
 
  // Scene118-Header
  Scene118Header: ()=>import(/* webpackChunkName: "pc.scene118header" */ 'templates/Scene118/Header'),
 
  // Scene120-左右两侧浮窗
  Scene120FloatSlider: ()=>import(/* webpackChunkName: "pc.scene120floatslider" */ 'templates/Scene120/FloatSlider'),
 
  // SceneKc122MGM-优惠
  Scene122Activity: ()=>import(/* webpackChunkName: "pc.scene122activity" */ 'templates/Scene122/Activity'),
 
  // SceneKc122MGM-Footer
  Scene122Footer: ()=>import(/* webpackChunkName: "pc.scene122footer" */ 'templates/Scene122/Footer'),
 
  // SceneKc122MGM-header
  Scene122Header: ()=>import(/* webpackChunkName: "pc.scene122header" */ 'templates/Scene122/Header'),
 
  // SceneKc122MGM-首页
  Scene122Home: ()=>import(/* webpackChunkName: "pc.scene122home" */ 'templates/Scene122/Home'),
 
  // SceneKc122MGM-热门游戏
  Scene122HotGame: ()=>import(/* webpackChunkName: "pc.scene122hotgame" */ 'templates/Scene122/HotGame'),
 
  // SceneKc122MGM-Live
  Scene122Live: ()=>import(/* webpackChunkName: "pc.scene122live" */ 'templates/Scene122/components/Live'),
 
  // SceneKc122MGM-Slider
  Scene122Slider: ()=>import(/* webpackChunkName: "pc.scene122slider" */ 'templates/Scene122/components/FloatSlider'),
 
  // 123-底部组件
  Scene123Footer: ()=>import(/* webpackChunkName: "pc.scene123footer" */ 'templates/Scene123/Footer'),
 
  // 255-导航组件
  Scene255Header: ()=>import(/* webpackChunkName: "pc.scene255header" */ 'templates/Scene255/Header'),
 
  // 澳门国际-底部组件
  SceneAmFooter: ()=>import(/* webpackChunkName: "pc.sceneamfooter" */ 'templates/ScenceAm/Footer'),
 
  // 澳门国际-导航组件
  SceneAmHeader: ()=>import(/* webpackChunkName: "pc.sceneamheader" */ 'templates/ScenceAm/Header'),
 
  // 首页场景五-BB
  SceneBB: ()=>import(/* webpackChunkName: "pc.scenebb" */ 'templates/SceneBB'),
 
  // BB-导航组件
  SceneBBHeader: ()=>import(/* webpackChunkName: "pc.scenebbheader" */ 'templates/SceneBB/Header'),
 
  // Hg-优惠活动列表
  SceneHgActivity: ()=>import(/* webpackChunkName: "pc.scenehgactivity" */ 'templates/SceneHg/Activity'),
 
  // Hg-手机投注界面
  SceneHgAppDownload: ()=>import(/* webpackChunkName: "pc.scenehgappdownload" */ 'templates/SceneHg/AppDownload'),
 
  // SceneHg-APP下载组件
  SceneHgDownload: ()=>import(/* webpackChunkName: "pc.scenehgdownload" */ 'templates/SceneHg/Download'),
 
  // Hg-底部组件
  SceneHgFooter: ()=>import(/* webpackChunkName: "pc.scenehgfooter" */ 'templates/SceneHg/Footer'),
 
  // Hg-游戏二级界面
  SceneHgGame: ()=>import(/* webpackChunkName: "pc.scenehggame" */ 'templates/SceneHg/Game'),
 
  // Hg-导航组件
  SceneHgHeader: ()=>import(/* webpackChunkName: "pc.scenehgheader" */ 'templates/SceneHg/Header'),
 
  // SceneHg-站点导航组件
  SceneHgSiteNav: ()=>import(/* webpackChunkName: "pc.scenehgsitenav" */ 'templates/SceneHg/components/MiddleNav'),
 
  // 首页场景六-澳门威尼斯
  sceneHk: ()=>import(/* webpackChunkName: "pc.scenehk" */ 'templates/SceneHk'),
 
  // HK-导航组件
  SceneHKHeader: ()=>import(/* webpackChunkName: "pc.scenehkheader" */ 'templates/SceneHk/Header'),
 
  // 首页场景四-美高梅
  SceneMg: ()=>import(/* webpackChunkName: "pc.scenemg" */ 'templates/SceneMg'),
 
  // MG-底部组件
  SceneMGFooter: ()=>import(/* webpackChunkName: "pc.scenemgfooter" */ 'templates/SceneMg/Footer'),
 
  // MG-导航组件
  SceneMGHeader: ()=>import(/* webpackChunkName: "pc.scenemgheader" */ 'templates/SceneMg/Header'),
 
  // P3-首页
  SceneP3: ()=>import(/* webpackChunkName: "pc.scenep3" */ 'templates/SceneP3/Home'),
 
  // 游戏场景二
  SceneP3Game: ()=>import(/* webpackChunkName: "pc.scenep3game" */ 'templates/SceneP3/SceneTwoGame'),
 
  // 首页场景三
  ScenePj: ()=>import(/* webpackChunkName: "pc.scenepj" */ 'templates/ScenePj'),
 
  // Pj-底部组件
  ScenePjFooter: ()=>import(/* webpackChunkName: "pc.scenepjfooter" */ 'templates/ScenePj/Footer'),
 
  // PJ-导航组件
  ScenePJHeader: ()=>import(/* webpackChunkName: "pc.scenepjheader" */ 'templates/ScenePj/Header'),
 
  // 首页场景八-狮朴
  SceneSpHome: ()=>import(/* webpackChunkName: "pc.scenesphome" */ 'templates/SceneSp'),
 
  // 首页场景七-亿博体育
  SceneYB: ()=>import(/* webpackChunkName: "pc.sceneyb" */ 'templates/SceneYiBo'),
 
  // 场景七-活动
  SceneYBActivity: ()=>import(/* webpackChunkName: "pc.sceneybactivity" */ 'templates/SceneYiBo/SceneActivity'),
 
  // 亿博-footer
  SceneYBFooter: ()=>import(/* webpackChunkName: "pc.sceneybfooter" */ 'templates/SceneYiBo/Footer'),
 
  // 游戏场景七
  SceneYBGame: ()=>import(/* webpackChunkName: "pc.sceneybgame" */ 'templates/SceneYiBo/SceneTwoGame'),
 
  // YB-导航组件
  SceneYBHeader: ()=>import(/* webpackChunkName: "pc.sceneybheader" */ 'templates/SceneYiBo/Header'),
 
  // 赞助详情页面-1
  SponsorOne: ()=>import(/* webpackChunkName: "pc.sponsorone" */ 'templates/ScenceYabo/Sponsor'),
 
  // 虚拟货币绑定
  UsdtTpl: ()=>import(/* webpackChunkName: "pc.usdttpl" */ 'templates/ScenceYabo/Usdt'),
 
  // 255-用户中心
  userInfo255: ()=>import(/* webpackChunkName: "pc.userinfo255" */ 'templates/Scene255/userInfo'),
 
  // 用户中心
  UserTpl: ()=>import(/* webpackChunkName: "pc.usertpl" */ 'templates/ScenceYabo/User'),
 
  // vip详情
  VipDetailTpl: ()=>import(/* webpackChunkName: "pc.vipdetailtpl" */ 'templates/ScenceYabo/Vip/VipDetail'),
 
  // VIP福利
  VipTpl: ()=>import(/* webpackChunkName: "pc.viptpl" */ 'templates/ScenceYabo/Vip/Vip'),
 
  // 玩彩-优惠活动二级界面
  WcActivity: ()=>import(/* webpackChunkName: "pc.wcactivity" */ 'templates/SceneWC/Activity'),
 
  // 玩彩-棋牌游戏二级界面
  WcBoardGame: ()=>import(/* webpackChunkName: "pc.wcboardgame" */ 'templates/SceneWC/BoardGame'),
 
  // 玩彩-新闻详情
  WcDetail: ()=>import(/* webpackChunkName: "pc.wcdetail" */ 'templates/SceneWC/Detail'),
 
  // 玩彩-H5开奖公告界面
  WcDrawNoticeH5: ()=>import(/* webpackChunkName: "h5.wcdrawnoticeh5" */ 'templates/SceneWC/DrawNoticeH5'),
 
  // 玩彩-电子游艺二级界面
  WCEntertainment: ()=>import(/* webpackChunkName: "pc.wcentertainment" */ 'templates/SceneWC/Entertainment'),
 
  // 玩彩-电竞游戏二级界面
  WcEsportsGame: ()=>import(/* webpackChunkName: "pc.wcesportsgame" */ 'templates/SceneWC/components/EsportsGame'),
 
  // 玩彩-捕鱼游戏二级界面
  WcFishingGame: ()=>import(/* webpackChunkName: "pc.wcfishinggame" */ 'templates/SceneWC/components/FishingGame'),
 
  // 玩彩-底部组件
  WcFooter: ()=>import(/* webpackChunkName: "pc.wcfooter" */ 'templates/SceneWC/Footer'),
 
  // 玩彩-游戏二级界面
  WcGame: ()=>import(/* webpackChunkName: "pc.wcgame" */ 'templates/SceneWC/Game'),
 
  // 玩彩-导航组件
  WcHeader: ()=>import(/* webpackChunkName: "pc.wcheader" */ 'templates/SceneWC/Header'),
 
  // 玩彩-首页
  WcHome: ()=>import(/* webpackChunkName: "pc.wchome" */ 'templates/SceneWC/Home'),
 
  // 玩彩-真人视讯二级界面
  WcLiveVideo: ()=>import(/* webpackChunkName: "pc.wclivevideo" */ 'templates/SceneWC/components/LiveVideo'),
 
  // 玩彩-彩票二级界面
  WCLottery: ()=>import(/* webpackChunkName: "pc.wclottery" */ 'templates/SceneWC/components/Lottery'),
 
  // 玩彩-手机购彩二级界面
  WcMLottery: ()=>import(/* webpackChunkName: "pc.wcmlottery" */ 'templates/SceneWC/MLottery'),
 
  // 玩彩-h5优惠活动二级界面
  WcMobileActivity: ()=>import(/* webpackChunkName: "h5.wcmobileactivity" */ 'templates/SceneWC/MobileActivity'),
 
  // 玩彩-下钻导航界面
  WcNavView: ()=>import(/* webpackChunkName: "h5.wcnavview" */ 'templates/SceneWC/MobileNavView'),
 
  // 玩彩-开奖公告
  WcOpenLottery: ()=>import(/* webpackChunkName: "pc.wcopenlottery" */ 'templates/SceneWC/OpenLottery'),
 
  // 玩彩-体育游戏二级界面
  WcSportsGame: ()=>import(/* webpackChunkName: "pc.wcsportsgame" */ 'templates/SceneWC/components/SportsGame'),
 
  // 玩彩-走势图二级界面
  WcTrendChart: ()=>import(/* webpackChunkName: "pc.wctrendchart" */ 'templates/SceneWC/TrendChart'),
 
  // 余额宝
  YuebaoTpl: ()=>import(/* webpackChunkName: "pc.yuebaotpl" */ 'templates/ScenceYabo/Yuebao'),
 
};
export default components;

import { defineComponent, PropType, Fragment, ref, computed } from "vue";
import { Row, Col } from "ant-design-vue";
import { DynamicEngine } from "commom";
import layout from "./layoutContainer.module.less";

const LayoutContainer = defineComponent({
  name: "LayoutContainer",
  props: {
    colSpan: {
      type: String as PropType<String>,
      default: "12:12",
    },
    innerElement: {
      type: Object as PropType<any>,
    },
    required: {
      type: Object as PropType<any>,
    },
    comtId: {
      type: String as PropType<String>,
    },
  },
  setup(props) {
    const colSpan = computed(() => props.colSpan.split(":"));

    const RenderChild = (index: number) => {
      const rowItem = props.innerElement?.[index];
      if (!rowItem) return null;
      // TODO item加类型
      return rowItem.map((item: any) => {
        if (item.enName === "LayoutContainer") {
          return (
            <LayoutContainer
              comtId={item.comtId}
              colSpan={props.colSpan}
              innerElement={item.innerElement}
            />
          );
        }

        return <DynamicEngine required={item} name={item.enName} />;
      });
    };
    return () => (
      <Fragment>
        <Row class={layout.row} data-label="布局容器">
          {colSpan.value.map((item, index) => (
            <Col span={item} class="layout-col">
              <div
                class={[!props.innerElement && layout.rowSolt, layout.rowContent]}
                data-type="box-wrap"
                data-element="LayoutContainer"
                data-comtId={props.comtId}
                data-comtId-key={index}
                // data-slot={`插槽（slot${index}）拖拽组件到此处`}
                data-slot={`slot${index}`}
              >
                {RenderChild(index)}
              </div>
            </Col>
          ))}
        </Row>
      </Fragment>
    );
  },
});

export default LayoutContainer;
